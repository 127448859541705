import { getAPIClientAsync } from '../../common/aws';
import type { ResponseListData } from './apiGetResponse';
import type Res from './apiGetResponse';

export type MediaTarget = 'label';
/**
 * target = landmarkの場合
 * office_id : 指定必須
 * appendix_id : 任意
 * vehicle_id : 指定不可
 *
 * target = 3dmodelの場合
 * vehicle_id : 指定必須
 * appendix_id : 任意
 * office_id : 指定不可
 *
 * target = labelの場合
 * appendix_id: 任意
 * office_id, vehicle_id: 指定不可
 */
export interface GetLoginMediaListReq {
  appendixID?: string;
  agencyName: string;
  target: MediaTarget;
  pageNum?: number;
  sortList?: string;
  limitNum?: number;
  env?: string;
}

export interface MediaItem {
  appendix_id: string;
  name: string;
  informations: Record<string, any>;
}
interface MediaFiles {
  url: string;
  expiration: number;
  filename: string;
}

export interface GetLoginMediaListDataItem extends MediaItem {
  target: MediaTarget;
  agency_name?: string;
  agency_id?: number;
  files: MediaFiles[];
}

export interface GetLoginMediaListData extends ResponseListData {
  media_resources: GetLoginMediaListDataItem[];
}

export interface GetLoginMediaListRes extends Res {
  data?: GetLoginMediaListData;
}

/**
 * @description ログイン前メディア情報一覧取得
 */
export default async function getLoginMediaListGet({
  appendixID,
  agencyName,
  target,
  pageNum,
  sortList,
  limitNum,
  env,
}: GetLoginMediaListReq): Promise<GetLoginMediaListRes> {
  const apigClient = env
    ? (window as any).apigClientFactory.newClient({}, env)
    : await getAPIClientAsync();
  const params = {
    appendix_id: appendixID ?? null,
    agency_name: agencyName ?? null,
    target,
    sort: sortList ?? '',
    page: pageNum ?? null,
    limit: limitNum ?? null,
  };

  const additionalParams = {
    headers: {},
    queryParams: {},
  };

  const body = {};

  return apigClient.getLoginMediaListGet(params, body, additionalParams);
}
