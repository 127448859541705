import * as types from '../../actions/geolocation/actionTypes';

export interface GeoLocationReducerState {
  coords: { [key: string]: any };
}
const defaultState = {
  coords: {},
};

/**
 * @description ブラウザから取得した緯度経度のReducer
 * @param {Object} [state=defaultState] reducer state
 * @param {Object} action redux actoon
 */
export default (state: GeoLocationReducerState = defaultState, action: any) => {
  switch (action.type) {
    case types.GEOLOCATION_WATCH_START:
    case types.GEOLOCATION_WATCH_STOP:
    case types.GEOLOCATION_CHANGE_NOTIFICATION: {
      return Object.assign({}, state, {
        ...action.payload,
      });
    }
    default:
      return state;
  }
};
