import * as types from '../../../actions/whitelabel/actionTypes';
import styleConstants from '../../../contents/css/constants/styleConstants';

export interface WhiteLabelReducerState {
  headerColor: string;
  iconColor: string;
  logoColor: string; // 現状使ってない
  menuBackgroundColor: string;
  menuBackgroundActiveColor: string;
  companyLogoUrl: string;
  companyLogoExpiration: number;
  serviceLogoUrl: string;
  serviceLogoExpiration: number;
  backgroundImageUrl: string;
  backgroundImageExpiration: number;
}

const defaultState: WhiteLabelReducerState = {
  headerColor: styleConstants.background.light.secondary,
  iconColor: styleConstants.background.light.secondary,
  logoColor: styleConstants.background.light.secondary,
  menuBackgroundColor: styleConstants.background.light.secondary,
  menuBackgroundActiveColor: styleConstants.background.light.secondary,
  companyLogoUrl: '',
  companyLogoExpiration: 0,
  serviceLogoUrl: '',
  serviceLogoExpiration: 0,
  backgroundImageUrl: '',
  backgroundImageExpiration: 0,
};

/**
 * ホワイトレーベル設定
 */
export default (state: WhiteLabelReducerState = defaultState, action: any = { type: '' }) => {
  switch (action.type) {
    case types.UPDATE_WHITE_LABEL:
      const newWhitelabel = action.payload;
      return Object.assign({}, state, newWhitelabel);

    default:
      return state;
  }
};
