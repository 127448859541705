import moment from 'moment';
import { LOG_DATETIME_FORMAT } from './constants';

/**
 * @description GAにログ送信する機能（そのうちGA使うのをやめるので利用は非推奨）
 * @param {string} message 送信するメッセージ
 * @param {string} level 送信レベル
 * @param {string} stacktrace スタックとレースを吐くか
 */
export default function sendLog(message: string, level?: string, stacktrace?: string) {
  // ここでログを送信する

  window.gtag(
    'event',
    'log',
    level,
    `${moment().format(LOG_DATETIME_FORMAT)} [${level}]
  ${message}
  ${stacktrace}`,
  );
}

const DEMO = true;
export function sendEventTracking(hittype: any, eventOptions: any) {
  if (!DEMO) {
    return;
  }

  // eslint-disable-next-line no-param-reassign
  eventOptions.value = eventOptions.value || moment().format(LOG_DATETIME_FORMAT);

  window.gtag(
    'event',
    `${moment().format(LOG_DATETIME_FORMAT)}__${hittype}--${window.loginUser.employee_id}`,
    eventOptions,
  );
}
