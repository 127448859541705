export const AKA = {
  100: '#FEE5D7',
  200: '#FEC4AF',
  300: '#FD9C88',
  400: '#FB766A',
  500: '#F93939',
  600: '#DC1A2B',
  700: '#B31C36',
  800: '#901232',
  900: '#770A30',
};

export const AO = {
  100: '#CEE5FC',
  200: '#9EC9F9',
  300: '#6BA5EF',
  400: '#4683E0',
  500: '#1155CC',
  600: '#0C41AF',
  700: '#083092',
  800: '#052176',
  900: '#031761',
};

export const KIIRO = {
  100: '#FFFFE3',
  200: '#FFE499',
  300: '#FFD266',
  400: '#FFBF3F',
  500: '#F2B004',
  600: '#F19F26',
  700: '#B76600',
  800: '#934C00',
  900: '#7A3A00',
};

export const MIDORI = {
  100: '#E3FBD3',
  200: '#C1F7A8',
  300: '#93E779',
  400: '#66D054',
  500: '#49C62D',
  600: '#34BD23',
  700: '#009617',
  800: '#00791D',
  900: '#085F12',
};

export const KURO = {
  100: '#FFFFFF',
  200: '#F7F9FA',
  300: '#F0F2F5',
  400: '#EDEDED',
  500: '#DDDDDD',
  600: '#D1D0D0',
  700: '#ABB0C8',
  800: '#424242',
  900: '#000000',
};

/**
 * @summary Company Default
 * @description サービステーマカラー
 * 事業所ごとに動的に設定されるカラー項目のデフォルト
 */
export const companyDefault = {
  icon: '#448AFF',
  header: '#004EA2',
  logo: '#001C98',
  menuBg: '#26344B',
  menuBgActive: '#192230',
};
