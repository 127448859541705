const defaultAuthority = {
  isAlertUpdate: false,
  isRollCall: false,
  isTwilioUse: false,
  isVehicleControl: false,
  isAssignment: false,
  isUpdate: false,
  isImport: false,
};

/**
 * @description ユーザの権限情報を取得
 * @param {string} url 未指定時はページのURLを元に権限を設定、指定時は指定された文字列を元に権限を設定
 * @returns 設定された権限の可否情報
 * 例：{ isUpdate: true }
 */
export default function getAuthority(url?: string) {
  let { auth } = window.loginUser;
  const { pathname } = window.location;
  if (!auth) {
    auth = {};
  }
  // console.log(auth);
  // 1. 点検登録 / アラート対応
  const roklCallAndAlert: { [index: string]: any } = {
    '/pages/alerts': { isUpdate: !!auth.is_observer },
    '/pages/running-vehicles': { isAlertUpdate: !!auth.is_observer },
    '/pages/assignment': { isRollCall: !!auth.is_observer },
  };

  // 2. 車内通話/車両制御指示
  const callAndVehicleControl: { [index: string]: any } = {
    common: { isTwilioUse: !!auth.is_controller && !!auth.can_access_personal_info },
    '/pages/running-vehicles': { isVehicleControl: !!auth.is_controller },
  };

  // 3. 運用設定
  const operationSettings: { [index: string]: any } = {
    '/pages/assignment': { isAssignment: !!auth.is_service_manager },
    '/pages/agency': { isUpdate: !!auth.is_service_manager },
    '/pages/check-presets': { isUpdate: !!auth.is_service_manager },
    '/pages/offices': { isUpdate: !!auth.is_service_manager },
  };

  // 4. 初期設定
  const initSetting: { [index: string]: any } = {
    '/pages/vehicles': { isUpdate: !!auth.is_setupper },
    '/pages/courses': { isUpdate: !!auth.is_setupper },
    '/pages/courses/detail': { isUpdate: !!auth.is_setupper },
    '/pages/employees': { isUpdate: !!auth.is_setupper },
    '/pages/busstops': { isUpdate: !!auth.is_setupper },
    '/pages/parent_routes': { isUpdate: !!auth.is_setupper },
    '/pages/routes': { isUpdate: !!auth.is_setupper },
    '/pages/trips': { isUpdate: !!auth.is_setupper },
    '/pages/setting': { isImport: !!auth.is_setupper },
    '/pages/areas': { isUpdate: !!auth.is_setupper },
    '/pages/day-reservation-presets': { isUpdate: !!auth.is_setupper },
    '/pages/autodrive-dep': { isUpdate: !!auth.is_setupper },
    '/pages/trip-lines': { isUpdate: !!auth.is_setupper },
  };

  // 5. 車両機器確認

  // 6. 個人情報
  // TODO:あとでauth.testをリプレスする
  const parsonalInfoSetting: { [index: string]: any } = {
    common: {
      isSettingUse: !!auth.can_access_personal_info,
      isCanAccessPersonalInfo: !!auth.can_access_personal_info,
    },
    '/pages/running-vehicles': {
      isVideoView: !!auth.can_access_personal_info,
      isAlertAdd: !!auth.can_access_personal_info,
    },
    '/pages/assignment': { isDetailView: !!auth.can_access_personal_info },
    '/pages/alertmap': {
      isViewOperator: !!auth.can_access_personal_info,
      isVideoView: !!auth.can_access_personal_info,
    },
    '/pages/reservations': {
      isCanAccessPersonalInfo: !!auth.can_access_personal_info,
    },
  };

  const findName = url || pathname;
  const authPattern1 = roklCallAndAlert[findName] || {};
  const authPattern2 = callAndVehicleControl[findName] || {};
  const authPattern3 = operationSettings[findName] || {};
  const authPattern4 = initSetting[findName] || {};
  const authPattern6 = parsonalInfoSetting[findName] || {};
  // 権限１〜６をマージしたものを返す
  return Object.assign(
    Object.assign({}, defaultAuthority),
    authPattern1,
    authPattern2,
    authPattern3,
    authPattern4,
    authPattern6,
  );
}
