import { getAPIClientAsync } from '../../common/aws';
import { VehicleCapabilities } from './getRunningDataDetail';
import { FeatureCollection } from 'geojson';

export interface LanguageType {
  jp?: string;
  en?: string;
}
export interface GetAgencyDefinitionAlertItem {
  target?: LanguageType;
  description?: LanguageType;
  todo?: LanguageType;
  metaInfo?: LanguageType;
  values: Array<{
    display?: {
      description?: LanguageType;
    };
    description?: string;
    level: number;
    value: number;
  }>;
}
export interface GetAgencyDefinitionAlert {
  codes: {
    [index: string]: GetAgencyDefinitionAlertItem;
  };
  keys: {
    [index: string]: GetAgencyDefinitionAlertItem;
  };
}
export interface GetAgencyDefinitionCapabilitie extends VehicleCapabilities {
  vehicle_type_id: number;
}
export interface GetAgencyDefinitionLanguage {
  vehicle_type_id: number;
  vehicle: {
    hardwares?: {
      [index: string]: any;
    };
    indicators?: {
      [index: string]: any;
    };
    values?: {
      [index: string]: any;
    };
    warning_lights?: {
      [index: string]: any;
    };
  };
  sercvice?: {
    values: {
      [index: string]: {
        jp?: string;
        en?: string;
        value: {
          [index: string]: LanguageType;
        };
      };
    };
  };
}
export interface GetAgencyDefinitionMessage {
  error: {
    [index: string]: LanguageType;
  };
}
export interface GetAgencyDefinitionData {
  alert: GetAgencyDefinitionAlert;
  capabilities?: GetAgencyDefinitionCapabilitie[];
  language: GetAgencyDefinitionLanguage[];
  message: GetAgencyDefinitionMessage;
  shapes: FeatureCollection[];
}
export interface GetAgencyDefinitionRes {
  data?: GetAgencyDefinitionData;
  status?: number;
  code?: string;
  message?: string;
}
/**
 * @description 事業所毎の設定ファイルを取得する
 */
export default async function getAgencyDefinition(): Promise<GetAgencyDefinitionRes> {
  const apigClient = await getAPIClientAsync();

  const params = {};

  const additionalParams = {
    headers: {},
    queryParams: {},
  };

  const body = {};

  return apigClient.getAgencyDefinitionGet(params, body, additionalParams);
}
