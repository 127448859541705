// 画面表示周りのステートを管理する
import * as types from '../../../actions/common/actionTypes';
import { passThrough } from '../../../actions/common/ButtonControlAction';

export interface CommonViewReducerState {
  isLoading: boolean;
  isInterceptionEnable: boolean;
  buttonDisableEvent: (e: any, callback: any) => boolean;
}
const defaultState = {
  isLoading: false,
  isInterceptionEnable: false,
  buttonDisableEvent: passThrough,
};

export default (state: CommonViewReducerState = defaultState, action: any) => {
  switch (action.type) {
    case types.START_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.STOP_LOADING:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case types.CONTROL_TRANSITION_BUTTON_DISABLED:
      return Object.assign({}, state, {
        ...action.payload,
      });

    default:
      return state;
  }
};
