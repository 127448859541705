import { ToastOptions } from 'react-toastify';

/**
 * @description 定数置き場
 */
export const SKIP = 1;
export const NOT_SKIP = 0;

export const INSERT = 'insert';
export const UPDATE = 'update';
export const DELETE = 'delete';

export const WEEKDAY = 0; //                      0 : 平日（月〜 金）
export const WEEKDAY_AND_SATURDAY = 1; //         1 : 平日（月〜土）
export const SATURDAY = 2; //                     2 : 土曜
export const SANDAY = 3; //                       3 : 日曜
export const NATIONAL_HOLIDAY = 4; //             4 : 祝日
export const SUNDAY_AND_NATIONAL_HOLIDAY = 5; //  5 : 日曜・祝日
export const PUBLIC_HOLIDAY = 6; //               6 : 土曜・日曜
export const HOLIDAY = 7; //                      7 : 土曜・日曜・祝日
export const ALL_DAY = 8; //                      8 : 全日

export const CONNECTION_OK = 1;
export const CONNECTION_ERROR = 0;

export const LANG_ENGLISH = 'en';
export const LANG_HIRAGANA = 'ja-Hrkt';

export const HAS_ALERT = 1;
export const HAS_NO_ALERT = 0;

export const VEHICLE_STATUS_OK = 0; // 正常
export const VEHICLE_STATUS_ERROR = 1; // 異常
export const VEHICLE_STATUS_CANNOT_RUN = 9; // 走行不可

export const SERVICE_MODE_STOPPING_AT_BUSSTOP = 1;
export const SERVICE_MODE_RUNNING = 0;

export const SET_STANDARD = 'standard';
export const SET_SALES = 'sales';
export const SET_OPERATION = 'operation';
export const SET_VEHICLE = 'vehicle';

export const ALERT_TYPE_DRIVINGSTANDUP = 'DrivingStandUpJudgment';
export const ALERT_TYPE_SAFETY_BEFORE_AUTO_DRIVING = 'SafetyBeforeAutoDriving';
export const ALERT_TYPE_INCARALARM = 'InCarAlarm';
export const ALERT_TYPE_BEFORESTOP = 'BeforeStopMoving';

export const ALERT_STATUS_DRIVINGSTANDUP_OK = 0;
export const ALERT_STATUS_DRIVINGSTANDUP_MOVING = 1;
export const ALERT_STATUS_SAFETY_BEFORE_AUTO_DRIVING_MOVING = 1;
export const ALERT_STATUS_INCARALARM_OK = 0;
export const ALERT_STATUS_INCARALARM_TURNOVER = 1;

export const ALERT_LEVEL_INFO = 0;
export const ALERT_LEVEL_WARNING = 1;

export const ALL_ALERTS = 'all';
export const ABRUPT_ACCELERATION = 'abrupt_acceleration';
export const ABRUPT_DECELERATION = 'abrupt_deceleration';
export const ABRUBT_STEERING = 'abrupt_steering';
export const OVERSPEED = 'overspeed';
export const IDLING = 'idling';
export const DRIVING_STANDUP_JUDGEMENT_MOVING = 'driving_standup_judgement_moving';
export const SAFETY_BEFORE_AUTO_DRIVING = 'safety_before_auto_driving_moving';
export const IN_CAR_ALARM_TURNOVER = 'in_car_alarm_turnover';

export const HTTP_OK = 200;

export const ALERT_NEW = 0;
export const ALERT_IN_PROGRESS = 1;
export const ALERT_DONE = 9;

export const ALERT_MAP_DATE_PERIOD_DAY = 'day';
export const ALERT_MAP_DATE_PERIOD_WEEK = 'week';
export const ALERT_MAP_DATE_PERIOD_MONTH = 'month';
export const ALERT_MAP_DATE_PERIOD_1MONTH_AGO = '1_month_ago';
export const ALERT_MAP_DATE_PERIOD_2MONTH_AGO = '2_month_ago';
export const ALERT_MAP_DATE_PERIOD_CUSTOM = 'custom';
export const ALERT_MAP_DATE_PERIOD_FULL = 'full_term';

export const ALERT_MAP_TIME_PERIOD_ALL = 'all';
export const ALERT_MAP_TIME_PERIOD_MORNING = 'morning';
export const ALERT_MAP_TIME_PERIOD_NOON = 'noon';
export const ALERT_MAP_TIME_PERIOD_NIGHT = 'night';

export const MAPTYPE_PINS = 'pins';
export const MAPTYPE_HEATMAP = 'heatmap';

export const TRIP_TYPE_ROUTE = 1;
export const TRIP_TYPE_PATROL = 2;

export const LOG_DATETIME_FORMAT = 'YYYY/MM/DD--HH:mm:ss.SSS';
export const CHART_ALERT_TYPE_AI_ALERT = 'AI_ALERT';
export const CHART_ALERT_TYPE_VEHICLE_ALERT = 'VEHICLE_ALERT';

export const IN_OPERATION_VEHICLE_DISPLAY_NORMAL = 'normal';
export const IN_OPERATION_VEHICLE_DISPLAY_SIMULATOR = 'simulator';

export const EMPLOYEE_STATUS_RETIREMENT = 4;

export const RUNNING_MODE_MANUAL_CONTROL = 1;
export const RUNNING_HAS_OVERRIDE_TRUE = 1;

export const VEHICLE_COLORS = [
  '#74CCCC',
  '#7474CC',
  '#CC74CC',
  '#CC7474',
  '#CCCC74',
  '#74CCA0',
  '#74A0CC',
  '#A074CC',
  '#CC74A0',
  '#CCA074',
  '#A0CC74',
  '#74CC74',
  '#389898',
  '#383898',
  '#983898',
  '#983838',
  '#989838',
  '#389868',
  '#386898',
  '#683898',
  '#983868',
  '#986838',
  '#689838',
  '#389838',
];
export const VEHICLE_DARK_COLORS = [
  '#54B3B3',
  '#5454B3',
  '#B354B3',
  '#B35454',
  '#B3B354',
  '#54B383',
  '#5483B3',
  '#8354B3',
  '#B35483',
  '#B38354',
  '#83B354',
  '#54B354',
  '#228080',
  '#222280',
  '#802280',
  '#802222',
  '#808022',
  '#228051',
  '#225180',
  '#512280',
  '#802251',
  '#805122',
  '#518022',
  '#228022',
];

export const EMERGENCY_STATE_NORMAL = 0;
export const EMERGENCY_STATE_FIRE = 1;
export const EMERGENCY_STATE_EARTHQUAKE = 2;
export const EMERGENCY_STATE_EMERGENCY = 3;
export const EMERGENCY_STATE_FAILURE = 4;
export const EMERGENCY_STATE_MSG_ERROR = -2;

export const INTERIOR_MESSAGE_LEVEL_INFOMATION = 0;
export const INTERIOR_MESSAGE_LEVEL_WARNING = 1;
export const INTERIOR_MESSAGE_LEVEL_ERROR = 2;

export const ALERT_TEXT = [
  '急旋回',
  '急発進・急加速',
  '急停止・急減速',
  '速度オーバー',
  '走行中移動',
  'バス停前移動',
  '着座前発進',
  '転倒',
];

export const ALERT_COLOR = [
  '#B35959',
  '#CCA074',
  '#B2B359',
  '#A0CC74',
  '#59B3B3',
  '#74A0CC',
  '#5959B3',
  '#A074CC',
];
export const ALERT_MAP_COLOR = [
  '#D96D8D',
  '#6ED2D9',
  '#DCBC76',
  '#A0C6E7',
  '#B5A2F4',
  '#A3D96D',
  '#F5A623',
  '#FFCC00',
  '#44A3BA',
];
export const ALERT_NAME = [
  'Vehicle_Vehicle-LateralAcceleration_1',
  'Vehicle_Vehicle-LongitudinalAcceleration_1',
  'Vehicle_Vehicle-LongitudinalAcceleration_2',
  'Vehicle_Vehicle-Speed_1',
  'AI_DrivingStandUpJudgment_1',
  'AI_BeforeStopMoving_1',
  'AI_SafetyBeforeAutoDriving_1',
  'AI_InCarAlarm_1',
];

export const SERVICE_STATE_BUSINESS_OPERATION = 0; // 営業
export const SERVICE_STATE_FOWARDING = 1; // 回送

export const ALERT_CONDITION_HAS_FLAG_TRUE = 1;
export const ALERT_CONDITION_HAS_FLAG_FALSE = 0;

export const MANUAL_ALERT = 'Common_Manual-Alert_1';
export const MANUAL_ALERT_TAG = 'Common_Manual-Alert';

export const MESSAGE_TYPE_VEHICLE_CONTROL = 0;
export const MESSAGE_TYPE_VEHICLE_STATUS_CHANGE = 1;

export const MESSAGE_TYPE_CUSTOM_TEXT = 99;

// ANA向け
export const READY_TO_START_GOAHEAD = 0;
export const READY_TO_START_WAIT_AFTER_START = 1;
export const READY_TO_START_WAIT_AFTER_CANCEL = 2;

export const VEHICLE_DETAIL_VEHICLE_MODE_STANDBY = 0;
export const VEHICLE_DETAIL_VEHICLE_MODE_USE = 1;
export const VEHICLE_DETAIL_VEHICLE_MODE_SAFETY = 2;

export const ICON_TYPE_MAP_ICON = 'mapIcon';
export const ICON_TYPE_LIST_ICON = 'listIcon';
export const VEHICLE_DETAIL_SERVICE_MODE_METRO = 0;
export const VEHICLE_DETAIL_SERVICE_MODE_BUS = 1;
export const VEHICLE_DETAIL_SERVICE_MODE_ONDEMAND = 2;

export const IOS_VERSION_FULLSCREEN_SUPPORTED = 12;

export const INTERNAL_MEMBER_DOMAINS = ['g.softbank.co.jp', 'yahoo-corp.jp', 'systena.co.jp'];

export const ALERT_SOUND_VOLUME = 0.5;

export const ALERT_SOUND_KEY = 'ALERT_SOUND_KEY';
export const ALERT_SOUND_MUTE_KEY = 'ALERT_SOUND_MUTE_KEY';

export const VIDEO_SOUND_KEY = 'VIDEO_SOUND_KEY';
export const VIDEO_SOUND_MUTE_KEY = 'VIDEO_SOUND_MUTE_KEY';

export const EXPIRATION_PERIOD = 365; // データが保持される日にち

export const ANALYSIS_TYPE_COURSE = 'ANALYSIS_TYPE_COURSE';
export const ANALYSIS_TYPE_DRIVER = 'ANALYSIS_TYPE_DRIVER';

export const DOOR_OPEN_INDEX = 1;
export const DOOR_OPEN_DISPLAY_STRING = 'OPEN';
export const DOOR_CLOSE_DISPLAY_STRING = 'CLOSE';
export const DOOR_OPEN_INDICATORS_VALUE = 'open';
export const DOOR_CLOSE_INDICATORS_VALUE = 'close';
export const DOOR_OPEN_CONTROL = 2;
export const DOOR_CLOSE_CONTROL = 1;
export const DOOR_FRONT = 'front';
export const DOOR_FRONT_KEY = 'front_door';
export const DOOR_BACK = 'back';
export const DOOR_BACK_KEY = 'back_door';

export const VEHICLE_INDICATOR_ON = 'on';
export const VEHICLE_INDICATOR_OFF = 'off';
export const VEHICLE_INDICATOR_LEFT = 'left';
export const VEHICLE_INDICATOR_RIGHT = 'right';

export const VEHICLE_DISPLAY_LEFT = 'left';
export const VEHICLE_DISPLAY_RIGHT = 'right';

export const VEHICLE_AIR_CONDITION_OFF = 0;
export const VEHICLE_AIR_CONDITION_ON = 1;
export const VEHICLE_AIR_CONDITION_AUTO = 2;

export const ANALYSIS_CHART_TYPE_BAR = 'bar';
export const ANALYSIS_CHART_TYPE_LINE = 'line';

export const ANALYSIS_UNIT_KM = 'km';
export const ANALYSIS_UNIT_HOUR = 'h';
export const ANALYSIS_UNIT_PERCENTAGE = 'percentage';

export const ANALYSIS_MAX_BAR_SIZE = 80;

export const LANGUAGE_DRIVER_KEY = 'common.vehicle_driver';
export const LANGUAGE_OPERATOR_KEY = 'common.vehicle_operator';

export const VEHICLE_HAZARD_ON = 1;

export const STOP_TYPES = {
  stop: 0, // 標柱 (GTFSに合わせてstop)
  station: 1, // 停留所 (GTFSに合わせてstation)
};

export const USE_TYPES = {
  scheduled: 0,
  demand: 1,
};

export const VEHICLE_STATES = {
  normal: 'normal',
  error: 'error',
};

export const CONGESTION_TYPE = {
  low: 1,
  middle: 3,
  high: 5,
};

export const PRESET_TARGETS = {
  office: 'office',
  area: 'area',
  vehicle: 'vehicle',
};

export const CHECK_TIME = {
  before: 0,
  after: 1,
};

export const IGNORE_ANALYSIS_VISIBLE = 0;
export const IGNORE_ANALYSIS_HIDDEN = 1;

export const SIP_CALL = 'sip';
export const VEHICLE_PREFIX = 'vehicle_';
export const SIP_INSIDE_CALL = 'sip_inside_call';
export const SIP_OUTSIDE_CALL = 'sip_outside_call';
export const SIP_ANNOUNCEMENT_CALL = 'sip_announcement_call';

export const FC_BUS = 7;
export const TIER4 = 11;
export const ACSL_DRONE = 15;
export const LINE_SETTING_DAY = 31;
export const LINE_SETTING_HOUR = 24;
export const LINE_SETTING_MINUTE = 60;

export const TOAST_OPTION: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
};
