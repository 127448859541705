/**
 * @summary 画面遷移時のダイアログを要求可否設定アクション
 * @description isInterceptionEnable=true時、
 *              他画面に遷移しようとするとcallbackのイベントが発生し、可否の要求を行うコールバックを返します。
 *              このコールバックにtrueを返すと遷移可、falseを返すと遷移不可となります。
 *              isInterceptionEnable=false時は、常に画面遷移を許可します。
 */
import * as actions from './actionTypes';

/**
 * @description 通常処理時、何もしないイベント
 * @param {Object} e onClick時のイベント、NULLの場合preventDefaultが実行されません。
 * @param {Function} callback Dialogでの遷移可時に呼ばれるイベント
 */
export function passThrough(e: any, callback: any) {
  if (callback) {
    callback(e);
  }
  return true;
}

let preventEvent: any;
let confirmDialogCb: any;
export function showDialog(e: any, callback: any) {
  if (!preventEvent) {
    return true;
  }
  let target: any;
  if (e && 'preventDefault' in e) {
    e.preventDefault();
    target = e.currentTarget;
  }
  confirmDialogCb((result: any) => {
    if (result === true) {
      if (callback) {
        callback(e);
      } else if (e && 'preventDefault' in e) {
        preventEvent = false;
        target.click();
      }
    }
  });
  return false;
}

/**
 * @description データ入力中に他画面に遷移しようとした時の処理
 * @param {Boolean} isInterceptionEnable true=他画面への遷移をブロックする
 * @param {Function} callback 遷移アクション発生時のイベント
 */
export function interceptTransition(isInterceptionEnable: boolean, callback: any) {
  let buttonDisableEvent = passThrough;
  if (isInterceptionEnable) {
    buttonDisableEvent = showDialog;
    preventEvent = true;
    confirmDialogCb = callback;
  }
  return {
    payload: {
      isInterceptionEnable,
      buttonDisableEvent,
    },
    type: actions.CONTROL_TRANSITION_BUTTON_DISABLED,
  };
}
