import { getAPIClientAsync } from '../../common/aws';
import { GetTripListDataItem } from './getTripList';

export interface GetRunningDataListMap {
  default_lat: number;
  default_lon: number;
  [index: string]: string | number; // その他
}

export type GetRunningDataListTripItem = Pick<GetTripListDataItem, 'trip_id' | 'trip_name'> & {
  trip_sequence: number;
  parent_route_color: string;
  parent_route_short_name: string;
  parent_route_text_color: string;
};

export interface GetRunningDataListOperation {
  vehicle_id: number;
  vehicle_name?: string;
  use_type?: number;
  course_id?: number;
  course_name?: string;
  course_number?: string;
  employee_id: number;
  employee_first_name?: string;
  employee_last_name?: string;
  trips?: GetRunningDataListTripItem[];
  area_id?: string;
  area_name?: string;
  reservation_key?: string;
  departure_time?: string;
  arrival_time?: string;
  drive_departure_time?: string;
  drive_arrival_time?: string;
}

export interface GetRunningDataListData {
  map: GetRunningDataListMap;
  operation: GetRunningDataListOperation[];
  websocket_url: string[];
}

export interface GetRunningDataListRes {
  data?: GetRunningDataListData;
  status?: number;
  code?: string;
  message?: string;
}

/**
 * @description 当日の運行情報を取得する
 */
export default async function getRunningDataList(): Promise<GetRunningDataListRes> {
  const apigClient = await getAPIClientAsync();

  const params = {};

  const additionalParams = {
    headers: {},
    queryParams: {},
  };

  const body = {};

  return apigClient.getRunningDataListGet(params, body, additionalParams);
}
