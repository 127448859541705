/**
 * @description firebase設定ファイル
 */
const config = {
  apiKey: 'AIzaSyCCXPPeVHlcMc8ms7OaJRAHDsDypW0plg4',
  authDomain: 'dispatcher-28d21.firebaseapp.com',
  databaseURL: 'https://dispatcher-28d21.firebaseio.com',
  projectId: 'dispatcher-28d21',
  storageBucket: 'dispatcher-28d21.appspot.com',
  messagingSenderId: '160607248171',
  appId: '1:160607248171:web:8ec65a1153457aac3faf6e',
};

export default config;
