import * as types from '../../actions/running/actionTypes';

export interface AgencyDefinitionReducerState {
  alert: {
    codes: { [key: string]: any };
    keys: { [key: string]: any };
  };
  capabilities: any[];
  language: any[];
  meessage: {};
  shapes: any[];
}
const defaultState = {
  alert: {
    codes: {},
    keys: {},
  },
  capabilities: [],
  language: [],
  meessage: {},
  shapes: [],
};

/**
 * @description アラートのPUSHに使うアラートメッセージ一覧のReducer
 * @param {Object} [state=defaultState] reducer state
 * @param {Object} action redux actoon
 */
export default (state: AgencyDefinitionReducerState = defaultState, action: any) => {
  switch (action.type) {
    case types.FETCH_AGENCY_DEFINITION_ERROR:
      return state;
    case types.FETCH_AGENCY_DEFINITION: {
      return Object.assign({}, state, {
        ...action.payload.data,
      });
    }
    default:
      return state;
  }
};
