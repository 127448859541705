export const FETCH_RUNNING_DATA = 'RUNNING_FETCH_RUNNING_DATA';
export const FETCH_DEMAND_RUNNING_DATA = 'FETCH_DEMAND_RUNNING_DATA';
export const DELETE_DEMAND_RUNNING_DATA = 'DELETE_DEMAND_RUNNING_DATA';
export const CLEAR_DEMAND_TIMER = 'CLEAR_DEMAND_TIMER';
export const FETCH_UPDATE_RUNNING_DATA = 'FETCH_UPDATE_RUNNING_DATA';
export const SELECT_VEHICLE = 'RUNNING_SELECT_VEHICLE';
export const FETCH_AGENCY_DEFINITION = 'RUNNING_FETCH_AGENCY_DEFINITION';
export const FETCH_AGENCY_DEFINITION_ERROR = 'RUNNING_FETCH_AGENCY_DEFINITION_ERROR';
export const CLEAR_RUNNING_DATA = 'CLEAR_RUNNING_DATA';

export const SET_LIVE_MAP = 'SET_LIVE_MAP';
export const CLEAR_LIVE_MAP = 'CLEAR_LIVE_MAP';

// Websocket関連
export const OPEN_WEBSOCKET = 'OPEN_WEBSOCKET';
export const CLOSE_WEBSOCKET = 'CLOSE_WEBSOCKET';
export const RECIEVIED_MESSAGE_WEBSOCKET = 'RECIEVIED_MESSAGE_WEBSOCKET';
export const SEND_MESSAGE_WEBSOCKET = 'SEND_MESSAGE_WEBSOCKET';
export const ADD_WEBSOCKET_LISTENER = 'ADD_WEBSOCKET_LISTENER';
export const REMOVE_WEBSOCKET_LISTENER = 'REMOVE_WEBSOCKET_LISTENER';
