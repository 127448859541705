import { getAPIClientAsync } from '../../common/aws';
import { ResponseData } from './apiPostResponse';

interface SubscribeAlertPushData extends ResponseData {
  vehicles: number[];
}

export interface SubscribeAlertPushRes {
  data: SubscribeAlertPushData;
}

/**
 * @description アラートPUSH用の情報を更新する
 * @param {String} token PUSHトークン
 */
export default async function subscribeAlertPush(token: string): Promise<SubscribeAlertPushRes> {
  const apigClient = await getAPIClientAsync();

  const params = {};
  const body = {
    token: token || null,
  };
  const additionalParams = {
    headers: {},
    queryParams: {},
  };

  return apigClient.subscribeAlertPushPost(params, body, additionalParams);
}
