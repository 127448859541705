import * as types from '../../actions/infra/actionTypes';
export interface InfraAlertReducerState {
  infra_alert_id: number;
  infra_id: number;
  infra_type_id: number;
  error_code: string;
  event_date: number;
  status: number;
  owner_employee_id: number;
  resoponse_date: number;
  repair_date: number;
  complete_date: number;
  image_url?: string;
}

const defaultState: InfraAlertReducerState = {
  infra_alert_id: 0,
  infra_id: 0,
  infra_type_id: 0,
  error_code: '',
  event_date: 0,
  status: 0,
  owner_employee_id: 0,
  resoponse_date: 0,
  repair_date: 0,
  complete_date: 0,
};

/**
 * 信号機のアラート情報が配信される。
 */
export default (state: InfraAlertReducerState = defaultState, action: any = { type: '' }) => {
  switch (action.type) {
    case types.SET_INFRA_ALERT:
      return action.payload;

    case types.CLEAR_INFRA_ALERT:
      return defaultState;

    default:
      return state;
  }
};
