import { LANGUAGE_DRIVER_KEY, LANGUAGE_OPERATOR_KEY } from '../common/constants';

export default {
  /* moment */
  'moment.locale': 'ja',
  'moment.timezone': 'Asia/Tokyo',
  'moment.locale.weekdays': ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  'moment.locale.weekdaysShort': ['日', '月', '火', '水', '木', '金', '土'],
  'moment.locale.weekdaysMin': ['日', '月', '火', '水', '木', '金', '土'],

  /* ログイン画面 */
  'login.insufficient.input': '未入力の項目があります。',
  'login.entered.value.policyerror':
    '新しいパスワードは英字(大文字、小文字)、数字、記号を含む、10桁以上を設定してください。',
  'login.entered.value.different': '入力された値が異なっています。',
  'login.fail.account.not.registered':
    'ログインに失敗しました。<br />アカウントが登録されていません。',
  'login.fail.account.policyerror': `仮パスワードが誤っている、または新しいパスワードが下記の要件に合っていません。<br />
  ・英字大文字、英字小文字、数字、記号を必ず含み、10桁以上を入力している。<br />
  ・記号として「^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ \`」を入力している。`,
  'login.fail.username.policyerror':
    'ユーザ名にスペースが含まれています。正しいユーザ名を入力してください。',
  'login.session.timeout':
    'セッションがタイムアウトしました。<br />お手数ですが、再度ログイン操作をお願いします。',
  'login.retry.login':
    'ログインに失敗しました。<br />お手数ですが、再度ログイン操作をお願いします。',
  'login.maintenance': '現在、サーバメンテナンス中です。',
  'login.fail': 'ユーザ名・パスワードが一致しません。',
  'login.fail.account.networkerror': 'ネットワークが不調です。時間を置いて再度お試しください。',
  'login.fail.cognito_undefined': '事業者名が不正です。正しいURLを指定してください。',
  'login.footer.sp_other_info': 'その他詳細情報はこちら',
  'login.footer.detail_info': '詳細情報',
  'login.footer.home_page': 'BOLDLY株式会社ホームページ',
  'login.footer.security_policy': '情報セキュリティポリシー',
  'login.footer.site_policy': 'サイトポリシー',
  'login.footer.parsonal_info': '個人情報の取り扱いについて',
  'login.footer.disclaimer': '免責事項',
  'login.footer.operating_environment': '動作推奨環境',
  'login.footer.unregistered_partner': 'パートナー未登録の方',
  'login.footer.sp_please_contact': 'BOLDLY株式会社にご連絡下さい',
  'login.footer.boldly_hp': 'BOLDLY HP',

  'login.auth.sign_in.username': 'ユーザー名',
  'login.auth.sign_in.username.placeholder': '入力',
  'login.auth.sign_in.pw': 'パスワード',
  'login.auth.sign_in.pw.placeholder_pc': '英字(大文字、小文字)、数字、記号を含む、10桁以上',
  'login.auth.sign_in.pw.placeholder_sp': '入力',
  'login.auth.sign_in.pw.annotation': '英字(大文字、小文字)、数字、記号を含む、10桁以上',
  'login.auth.sign_in.button_login': 'ログイン',

  'login.auth.setup_totp.init': '初期設定',
  'login.auth.setup_totp.title': '2要素認証セットアップ開始',
  'login.auth.setup_totp.description': 'アカウントを安全に使用するために2要素認証が必要です。',
  'login.auth.setup_totp.info_text1':
    '1.スマートフォンにGoogle Authenticator　アプリをインストールし開いてください',
  'login.auth.setup_totp.link_appdownload': 'アプリインストール',
  'login.auth.setup_totp.info_text2':
    '2.アプリ内の「QRコードをスキャン」ボタンよりカメラを起動、QRコードをスキャンしてください',
  'login.auth.setup_totp.link_sercret1': 'QRコードが読み込めない方',
  'login.auth.setup_totp.link_sercret2': 'セットアップキーの入力の場合はこちら',
  'login.auth.setup_totp.button_next': '次へ',
  'login.auth.setup_totp.title_sp': '初期設定を行ってください',
  'login.auth.setup_totp.description_sp': `初期設定が完了していません　
  パソコンより初期設定を行い　
  再度ログインをお願いいたします`,
  'login.auth.appdownload.title': 'Google Authenticatorアプリインストール',
  'login.auth.appdownload.description': 'QRコードからアプリをインストールして開いてください',
  'login.auth.secret_key.description1': 'セットアップキー入力ボタンから',
  'login.auth.secret_key.description2': 'セットアップキーを入力してください。',
  'login.auth.secret_key.copy': 'コピーする',
  'login.auth.force_new_password.new_pw': '新しいパスワード',
  'login.auth.force_new_password.new_pw.placeholder_pc':
    '英字(大文字、小文字)、数字、記号を含む、10桁以上',
  'login.auth.force_new_password.new_pw.placeholder_sp': '入力',
  'login.auth.force_new_password.new_pw.annotation':
    '英字(大文字、小文字)、数字、記号を含む、10桁以上',
  'login.auth.force_new_password.new_pw_re': '新しいパスワード(再入力)',
  'login.auth.force_new_password.button_login': 'ログイン',
  'login.auth.confirm_totp.title': 'ワンタイムパスワード認証',
  'login.auth.confirm_totp.description1': 'Google Authenticatorアプリを開き',
  'login.auth.confirm_totp.description2': '数字6桁のパスワードを入力してください。',
  'login.auth.confirm_totp.retry_message':
    '認証トークンの値が変更後にもう一度数値を入力してください',
  'login.auth.confirm_totp.totp': 'ワンタイムパスワード',
  'login.auth.confirm_totp.totp.placeholder': '数字6桁(半角スペース不要)',
  'login.auth.confirm_totp.button_auth': '認証',
  'login.auth.confirm_totp.button_retry': '最初からやり直す',
  'login.auth.confirm_totp.session_timeout':
    'セッションの有効期限が切れました。<br />恐れ入りますが、最初からやり直してください。',

  'login.auth.reset_password.title': 'パスワードをお忘れですか？',
  'login.auth.reset_password.description1': '登録されているユーザー名を入力してください',
  'login.auth.reset_password.description2': '仮パスワードをお送りいたします',
  'login.auth.reset_password.username': 'ユーザー名',
  'login.auth.send_user_name.mail': 'メールアドレス',
  'login.auth.send_user_name.title': 'ユーザー名をお忘れですか？',
  'login.auth.send_user_name.description1': '登録されているメールアドレスを入力してください。',
  'login.auth.send_user_name.description2': 'ユーザー名をお送りいたします。',
  'login.auth.reset_password.username.placeholder': '入力',
  'login.auth.reset_password.username.button.submit': '送信',
  'login.auth.reset_password_complete.title1': '仮パスワードをご登録の',
  'login.auth.reset_password_complete.title2': 'メールアドレスに送信しました',
  'login.auth.reset_password_complete.title_user': 'ユーザー名をご登録の',
  'login.auth.reset_password_complete.description1': '送信されたメールの内容にそって',
  'login.auth.reset_password_complete.description2': 'ログインを行ってください',

  'login.auth.footer.contact': 'お問い合わせ',
  'login.auth.footer.password_reset': 'パスワードをお忘れの方はこちら',
  'login.auth.footer.send_username': 'ユーザー名をお忘れの方はこちら',
  'login.other_lang': 'English',
  'login.other_lang_value': 'en',
  'login.no.information': 'お知らせはありません',
  'login.error.information': 'お知らせ情報を取得できませんでした',
  'login.getting.information': 'お知らせ取得中',
  'login.no.agency.message': 'URLに事業者IDが設定されていません。\n事業者IDを入力してください。',

  /* 運行中車両一覧 */
  'map.title': '走行中車両一覧',
  'map.minutes': '分',
  'map.style.normal': '地図',
  'map.style.satellite': '航空',
  'map.style.normal.describe': '地図',
  'map.style.satellite.describe': '航空写真',

  /* 運行中車両一覧 */
  'detail.title': '走行中車両詳細',

  /* お知らせ一覧 */
  'information.info': 'お知らせ',
  'information.additional': '機能追加/変更',
  'information.bug': '障害報告',
  'information.maintenance': 'メンテナンス',
  'information.campaign': 'キャンペーン',
  'information.other': 'その他',
  'information.close': '閉じる',
  'information.oldinfo': '過去のお知らせ',
  'information.button.select': '反映',
  'information.message.error_fetch_information': 'お知らせの取得に失敗しました',

  /* ヘッダ及び通話ダイアログ */
  'calldialog.vehicle_name_acquiring': '取得中',
  'calldialog.vehicle_name_error': '取得できません',
  'calldialog.call_hangup': '拒否',
  'calldialog.call_receiving': '応答',

  /* サイドバー */
  'sidebar.dashboard': 'ダッシュボード',
  'sidebar.map': '遠隔監視',
  'sidebar.schedule': '{0}・車両アサイン',
  'sidebar.demand': '{0}アサイン・予約',
  'sidebar.reservation': '予約一覧',
  'sidebar.reports': '統計情報',
  'sidebar.settings': '設定',
  'sidebar.alertmap': '分析',

  /* ダッシュボード */
  'dashboard.title': 'ダッシュボード',
  'dashboard.select_driver': '乗務員名',
  'dashboard.welcome1.morning': 'おはようございます。',
  'dashboard.welcome1.daytime': 'こんにちは。',
  'dashboard.welcome1.evening': 'こんばんは。',
  'dashboard.welcome2': 'さん',
  'dashboard.summary': '前回乗務のスコア',
  'dashboard.alert_category_name_error': 'エラー',
  'dashboard.incomplete_alert1': '未対応のアラームが',
  'dashboard.incomplete_alert2': '件あります。',
  'dashboard.incomplete_alert_all': '全てをみる',
  'dashboard.arr': '着',
  'dashboard.dep': '発',
  'dashboard.route_name': '系統名',
  'dashboard.trips': '便',
  'dashboard.today_schedule': '本日の予定',
  'dashboard.no_data': '予定はありません',
  'dashboard.stoppage_time': '停車時間',
  'dashboard.loading': '-',
  'dashboard.datetime': '日時',
  'dashboard.vehicles.type': '車種',
  'dashboard.message.error_fetch_untouched_alerts': '未処理アラートの取得に失敗しました',
  'dashboard.message.error_fetch_todays_course': '行路情報の取得に失敗しました',
  'dashboard.message.error_fetch_employees': '社員情報の取得に失敗しました',

  /* 運行中車両画面 */
  'in_operation_vehicle.arr': '着',
  'in_operation_vehicle.dep': '発',
  'in_operation_vehicle.control.internal_call': '車内通話',
  'in_operation_vehicle.control.external_call': '車外通話',
  'in_operation_vehicle.control.start': '発車',
  'in_operation_vehicle.control.stop': '停車',
  'in_operation_vehicle.control.deceleration.start': '通知中',
  'in_operation_vehicle.control.complete': '通知完了',
  'in_operation_vehicle.control.deceleration.complete1': '通知',
  'in_operation_vehicle.control.deceleration.complete2': '完了',
  'in_operation_vehicle.control.departure.start': '通知中',
  'in_operation_vehicle.control.departure.complete1': '通知',
  'in_operation_vehicle.control.departure.complete2': '完了',
  'in_operation_vehicle.section_title.route_location': '運行情報',
  'in_operation_vehicle.section_title.speed_occupancy': '速度&乗車率',
  'in_operation_vehicle.section_title.shuttle_information': '車載システム状況',
  'in_operation_vehicle.section_title.drive_information': '運行情報',
  'in_operation_vehicle.liveview.no_camera': 'カメラがありません',
  'in_operation_vehicle.alert.urgent_task': '緊急対応タスク',
  'in_operation_vehicle.alert.first': '初回発生時刻',
  'in_operation_vehicle.alert.update': '更新時刻',
  'in_operation_vehicle.alert.title': '異常検知情報',
  'in_operation_vehicle.button.show_cameras': 'カメラ一覧表示',
  'in_operation_vehicle.running.mode.manual': 'MANUAL',
  'in_operation_vehicle.running.mode.auto': 'AUTO',
  'in_operation_vehicle.running.mode.override': 'OVERRIDE',
  'in_operation_vehicle.display.normal': '通常',
  'in_operation_vehicle.display.simulator': 'シミュレータ',
  'in_operation_vehicle.people': '人',
  'in_operation_vehicle.trips.arr': '到着',
  'in_operation_vehicle.trips.dep': '出発',
  'in_operation_vehicle.miniute': '分',
  'in_operation_vehicle.miniute_delay': '遅延',
  'in_operation_vehicle.eta': '分後到着',
  'in_operation_vehicle.camera-1': 'カメラ画面1',
  'in_operation_vehicle.camera-2': 'カメラ画面2',
  'in_operation_vehicle.camera-3': '車内映像',
  'in_operation_vehicle.camera-4': '車外映像',
  'in_operation_vehicle.message.confirm_stop_shuttle':
    '車両に停止指示を送りますが、よろしいですか？',
  'in_operation_vehicle.message.confirm_start_shuttle':
    '車両に走行開始指示を送りますが、よろしいですか？',
  'in_operation_vehicle.message.confirm_shuttle': '車両に指示を送りますが、よろしいですか？',
  'in_operation_vehicle.message.no_support_browser':
    'ご利用のブラウザではこのタイプの動画は再生できません',
  'in_operation_vehicle.message.no_video': '映像が取得できませんでした',
  'in_operation_vehicle.message.notify_stop_shuttle': '車両に停止指示を送りました',
  'in_operation_vehicle.message.faild_stop_shuttle': '停車に失敗しました',
  'in_operation_vehicle.message.faild_start_shuttle': '発車に失敗しました',
  'in_operation_vehicle.connected': '接続中',
  'in_operation_vehicle.disconnected': '未接続',
  'in_operation_vehicle.poweroff': '電源オフ',
  'in_operation_vehicle.course_name': '行路名：',
  'in_operation_vehicle.parent_route_short_name': '系統名：',
  'in_operation_vehicle.trip_name': '便名　：',
  'in_operation_vehicle.current_location': '現在地：',
  'in_operation_vehicle.location_indent': '　　　　',
  'in_operation_vehicle.resolver_img': 'batch_user.svg',
  'in_operation_vehicle.running.mode.auto.driving': '自動運行中',
  'in_operation_vehicle.running.mode.manual.driving': '手動運行中',
  'in_operation_vehicle.alert.target.unknown_alert': '不明なアラート',
  'in_operation_vehicle.alert.message.unknown_alert': '不明なアラートです。',
  'in_operation_vehicle.goto_detail': '詳細へ移動',
  'in_operation_vehicle.controll.button.emergency': '緊急停止',
  'in_operation_vehicle.controll.button.missionCancel': `行先取消`,
  'in_operation_vehicle.controll.button.connectionReset': `ハード\nリセット`,
  'in_operation_vehicle.controll.button.admission': '入場',
  'in_operation_vehicle.controll.button.orbiting': '周回走行',
  'in_operation_vehicle.controll.button.pause': '一時停止',
  'in_operation_vehicle.controll.button.exit': '退出',
  'in_operation_vehicle.controll.button.emergency.landing': '緊急着陸',
  'in_operation_vehicle.controll.button.gohome': 'ゴーホーム',
  'in_operation_vehicle.controll.button.landing': '自動着陸',
  'in_operation_vehicle.controll.button.takeoff': '自動離陸',
  'in_operation_vehicle.audio.disabled': '音声の再生が途切れました',
  'in_operation_vehicle.video.disabled': '映像の再生が途切れました',
  'in_operation_vehicle.camera.disabled': '映像の再生が途切れました',
  'in_operation_vehicle.server.disabled': '映像配信サーバーとの接続に失敗しました。',

  /* 運行スケジュール画面 */
  'calendar.title': '路線バス {0}・車両アサイン',
  'calendar.subtitle.course_assign': '行路別アサイン情報',
  'calendar.trip': '走行便',
  'calendar.course_id': '行路番号',
  'calendar.course_name': '行路名',
  'calendar.start_time': '開始時間',
  'calendar.end_time': '終了時間',
  'calendar.hours_worked': '勤務時間',
  'calendar.interval': '休憩時間',
  'calendar.break_time': '休憩時間',
  'calendar.employee': '{0}',
  'calendar.shuttle_type': '車両',
  'calendar.arr': '着',
  'calendar.dep': '発',
  'calendar.next_day_abbreviation': '翌',
  'calendar.supervisor': '遠隔監視者',
  'calendar.patrol_interval': '運行間隔（分）',
  'calendar.blocking_reservation': '予約制限中',

  'calendar.error.empty_trips': '行路に便が設定されていません。便を設定して下さい。',
  'calendar.error.empty_drive_date': '運行日が指定されていません',
  'calendar.error.bad_format_drive_date': '指定の運行日が正しくありません',
  'calendar.error.duplicate_employee_id': '同じ社員をアサインすることはできません',
  'calendar.error.duplicate_vehicle_id': '同じ車両をアサインすることはできません',
  'calendar.error.bigin_time_not_found': '運行開始時間が見つかりませんでした',
  'calendar.error.end_time_not_found': '運行終了時間が見つかりませんでした',
  'calendar.error.duplicate_time': '車両の運行時間が重複しています',

  /* 運行予約画面 */
  'reservation.tab.title': '路線',
  'reservation.tab.title.ondemand': '配車',
  'reservation.tab.title.other': 'その他',
  'reservation.parking': 'パーキング',
  'reservation.button.earliest': '最短',
  'reservation.title': '配車バス オペレーターアサイン・予約',
  'reservation.schedule': '日程',
  'reservation.departure_stop': '出発',
  'reservation.departure_time': '走行開始時間',
  'reservation.departure_point': '出発地',
  'reservation.arrival_stop': '到着',
  'reservation.arrival_time': '走行終了時間',
  'reservation.arrival_point': '到着地',
  'reservation.drive_date': '出発時間',
  'reservation.vehicle_name': '車両',
  'reservation.capacity': '最大乗車人数',
  'reservation.driver': 'オペレーター',
  'reservation.detail': '予約詳細',
  'reservation.detail.search': '車両空き状況確認',
  'reservation.detail.available.vehicle_number': '管理番号',
  'reservation.detail.available.vehicle_name': '車両名',
  'reservation.detail.available.total_passengers': '乗車人数',
  'reservation.detail.available.arrival_time': '到着時間',
  'reservation.detail.available.no_data': '0件です。他の条件で検索して下さい。',
  'reservation.driver_assign': 'オペレーターアサイン',
  'reservation.display_course': '行路表示',
  'reservation.passenger_info': 'お客様情報',
  'reservation.select_vehicle': '選択中車両',
  'reservation.passenger.name': '氏名',
  'reservation.passenger.name.placeholder': '氏名を入力して下さい',
  'reservation.passenger.tel': '連絡先',
  'reservation.passenger.passenger': '人数',
  'reservation.passenger.representative': '代表者',
  'reservation.passenger.representative.placeholder': '氏名を入力して下さい',
  'reservation.check': '点検',
  'reservation.check.before_operation': '運行前',
  'reservation.check.before_operator': '登録者',
  'reservation.check.before_operation_date': '登録日',
  'reservation.check.after_operation': '運行後',
  'reservation.check.after_operator': '登録者',
  'reservation.check.after_operation_date': '登録日',
  'reservation.check.check_confirmation': '点呼・ODD・車両機器 確認',
  'reservation.check.area': '走行エリア',
  'reservation.category': '種別',
  'reservation.content': '内容',
  'reservation.start_time': '開始時間',
  'reservation.end_time': '終了時間',

  'reservation.passenger.dialog.title': '乗客情報',
  'reservation.passenger.dialog.describe1': '乗客情報登録ができます。',
  'reservation.passenger.dialog.describe2': '乗車、降車、お名前、人数は必須項目です。',
  'reservation.passenger.dialog.boarding': '乗車',
  'reservation.passenger.dialog.alighting': '降車',
  'reservation.passenger.dialog.name': 'お名前',
  'reservation.passenger.dialog.number_of_person': '人数',
  'reservation.passenger.dialog.tel': '連絡先',
  'reservation.passenger.dialog.subscriber': '予約元',

  /* 予約一覧画面 */
  'reservation.passenger.list.csv.button': 'CSVダウンロードはこちら',
  'reservation.passenger.list.csv.downloading': 'CSVダウンロード中。しばらくお待ち下さい...',
  'reservation.passenger.list.csv.error': 'CSVダウンロード処理に失敗しました。',
  'reservation.passenger.list.area': 'エリア',
  'reservation.passenger.total': '予約人数',
  'reservation.passenger.check': '点呼・ODD・車両機器',
  'reservation.passenger.mode.day': '当日モード',
  'reservation.passenger.mode.analysis': '分析モード',

  'reservation.preset': 'プリセット',
  'reservation.preset.button.regist': 'プリセット登録',
  'reservation.preset.button.load': 'プリセット読込',
  'reservation.preset.dialog.load.button': '読み込み',
  'reservation.preset.dialog.load.description':
    '登録済みの予約内容を読み込んで入力することができます。',
  'reservation.preset.dialog.regist.button': '登録',
  'reservation.preset.dialog.regist.description':
    '入力済みの予約内容を登録してあとから読み込んで再入力することができます。',
  'reservation.preset.title': '予約プリセット',
  'reservation.preset.category': 'カテゴリー',
  'reservation.preset.name': 'プリセット名',
  'reservation.preset.operator': '登録者',
  'reservation.preset.operation_date': '登録日時',
  'reservation.': '終了時間',
  'reservation.category.route': '路線予約',
  'reservation.category.ondemand': 'オンデマンド予約',
  'reservation.category.semidemand': '定時便予約',
  'reservation.day_preset.button.load': '1日プリセット読込',
  'reservation.day_preset.dialog.title': '1日プリセット読込',
  'reservation.day_preset.dialog.desc': '1日プリセットを呼び出すことができます。',
  'reservation.day_preset.dialog.detail.title.name': '予約プリセット名',
  'reservation.day_preset.dialog.isValidPreset':
    '車両に紐づくパーキング情報が重複しているため適用できないプリセットです。',
  'reservation.day_preset.regist.dialog.isValidPreset':
    'パーキング情報間の出発時間に不整合が起きています。',
  'reservation.day_preset.regist.dialog.title': '1日プリセット作成',
  'reservation.day_preset.regist.dialog.desc': '1日プリセットを作成・編集することができます。',
  'reservation.day_preset.regist.dialog.select_desc': '予約プリセットを選択してください',

  'reservation.error.empty_vehicle1': '予約がご準備できませんでした',
  'reservation.error.empty_vehicle2': '再度時間を変えて検索をお願いします',
  'reservation.error.empty_agency_id': '事業者情報が取得できません',
  'reservation.error.not_match_reservation_key': '対象となる予約が存在しません',
  'reservation.error.passeneger_num_is_over_capacity': '乗車人数をオーバーしています',
  'reservation.error.reservation_no_passenger1': 'お客様情報がありません。',
  'reservation.error.reservation_no_passenger2': '予約を削除するか、お客様情報を追加してください。',
  'reservation.error.invalid_drive_date': '運行日が不正な値です',
  'reservation.error.use_type_is_invalid': '用途が存在しません',
  'reservation.error.not_match_parking_id': '指定されたバス停が存在しません',
  'reservation.error.use_type_or_agency_id_invalid': '事業者情報か用途が存在しません',
  'reservation.error.trip_setting_not_enough': '指定されたルートの経路は存在しません',
  'reservation.error.alraedy_exist_another_reservation':
    '指定された時間は他の予約が既に存在しています',
  'reservation.error.reservation_is_not_accepted': '業務外の時間が指定されています',
  'reservation.error.passenger_key_not_found': '指定されたお客様情報は存在しません',
  'reservation.error.alraedy_exist_another_employee_assign': '{0}が別の車両と重複しています',
  'reservation.error.not_exist_travel_data': 'この経由は存在しません。',
  'reservation.error.ask_boldly': 'BOLDLYに問い合わせください。',
  'reservation.error.travel_data_system_error': '経由地間隔の時間取得でエラーが発生しました。',
  'reservation.error.please_retry': '時間を置いて再度お試しください。',
  'reservation.error.partial_registration_completed':
    '一部の予約が適用できませんでした。登録できなかった予約については個別に登録をしてください。',
  'reservation.error.require_vehicle': '車両は必須です',
  'reservation.area.information.enable': '※この便はLINEから予約が可能です。',
  'reservation.area.information.disable_1': '※この便は現在LINEから',
  'reservation.area.information.disable_2': '予約できません。',
  'reservation.area.information.disable_3': '設定変更は',
  'reservation.area.information.disable_4': 'こちら',
  'reservation.area.information.disable_5': 'から',
  'reservation.area.information.time_1': '予約期間：',
  'reservation.area.information.time_2': '〜',

  /* 予約プリセット設定画面 */
  'reservation.setting.button': 'プリセット設定画面へ',
  'reservation.setting.title': 'プリセット設定画面',
  'reservation.setting.description': '予約プリセットの管理・閲覧ができます',
  'reservation.setting.dialog.description': 'プリセット名を変更することができます',
  'reservation.setting.busstop_name': 'バス停名',
  'reservation.setting.busstop_time': '時間',
  'reservation.setting.table.th.date': '登録日',
  'reservation.setting.table.th.preset': 'プリセット',
  'reservation.setting.table.th.register': '登録者',
  /* 削除前1日プリセット一覧画面 */
  'reservation.day.list.title': '1日プリセット一覧',
  'reservation.day.list.desc1': '1日プリセットに登録されているため、削除ができません。',
  'reservation.day.list.desc2':
    '対象の予約プリセットを1日プリセットから削除もしくは差し替えてください。',
  'reservation.day.list.header1': '1日プリセット名',
  'reservation.day.list.complete': '対象の予約プリセットの削除が可能です。',

  /* 1日プリセット設定画面 */
  'reservation.day.setting.button': '1日プリセット設定画面へ',
  'reservation.day.setting.title': '1日プリセット設定画面',
  'reservation.day.setting.description': '1日プリセットの管理・閲覧ができます',

  /* レポート一覧画面 */
  'statistics.title': 'レポート一覧',
  'statistics.sidebar_title': 'レポート表示設定',
  'statistics.showing_item': '表示選択／順序',
  'statistics.showing_item.standard': '標準セット',
  'statistics.showing_item.running_management': '運行管理セット',
  'statistics.showing_item.sales_analysis': '売上分析セット',
  'statistics.showing_item.shuttle_management': '車両管理セット',
  'statistics.speed': '速度',
  'statistics.rpm': 'エンジン回転数',
  'statistics.alert': 'アラート有無',
  'statistics.passenger_number': '乗客人数',
  'statistics.travel_distance': '走行距離',
  'statistics.sales': '売上',
  'statistics.running_mode': '走行モード',
  'statistics.wheelchair': '車椅子有無',
  'statistics.acceleration': '加速度',
  'statistics.temperature': '室温',
  'statistics.network_intensity': '通信強度',
  'statistics.transmit_data': '送信データ量',
  'statistics.receive_data': '受信データ量',
  'statistics.yourself': '自分',
  'statistics.employee': '社員',
  'statistics.shuttle': '車両',
  'statistics.course': '行路',
  'statistics.alert_priority': '重要度',
  'statistics.alert_priority.low': '低',
  'statistics.alert_priority.middle': '中',
  'statistics.alert_priority.high': '高',
  'statistics.alert_priority.priority_low': '重要度 低',
  'statistics.alert_priority.priority_middle': '重要度 中',
  'statistics.alert_priority.priority_high': '重要度 高',
  'statistics.alert_priority.no_alert': 'アラートなし',
  'statistics.running_mode.auto_steering': '自動ステアリング',
  'statistics.running_mode.manual_steering': '手動ステアリング',
  'statistics.running_mode.auto_brake': '自動ブレーキ',
  'statistics.running_mode.manual_brake': '手動ブレーキ',
  'statistics.running_mode.auto_or_manual': '自動/手動運転',
  'statistics.running_mode.auto_drive': '自動運転',
  'statistics.running_mode.manual_drive': '手動運転',
  'statistics.running_mode.no_drive': '無し',
  'statistics.equip_or_no': '有無',
  'statistics.button.select': '反映',
  'statistics.switch_button.a_day': '1日',
  'statistics.switch_button.period': '期間',
  'statistics.placeholder': '選択して下さい',
  'statistics.placeholder.require_select': '1つ以上選択して下さい',
  'statistics.message.all_delete': '全て削除',
  'statistics.message.no_result': '見つかりませんでした',
  'statistics.message.no_data': 'データがありません',
  'statistics.message.from_to_error': '期間の指定が正しくありません',
  'statistics.type.course': '行路分析',
  'statistics.type.driver': '{0}分析',

  /* 設定画面 */
  'settings.title': '設定',
  'settings.table_title.shuttle': '車両',
  'settings.table_title.timetable_revision': 'ダイヤ',
  'settings.table_title.company': '基本情報',
  'settings.table_title.others': 'その他',
  'settings.shuttle_list': '車両',
  'settings.parentroute_route': '系統・経路・便',
  'settings.course': '行路',
  'settings.fare': '運賃',
  'settings.busstop': '停留所',
  'settings.languages': '外国語翻訳',
  'settings.employee_list': '社員',
  'settings.office_list': '営業所',
  'settings.company_info': '事業者情報',
  'settings.check_presets': '点検',
  'settings.route_setting': '運行設定',
  'settings.workshifts_setting': 'シフト設定',
  'settings.bus_shared_format': 'バス標準フォーマット',
  'settings.gtfs_title': 'GTFS.ZIP',
  'settings.log': '操作ログ',
  'settings.import': 'インポート',
  'settings.help': 'ヘルプ',
  'settings.export': 'エクスポート',
  'settings.after_ph2': 'Phase 2.0 予定',
  'settings.add.manual': '手動登録・編集',
  'settings.add.format': '標準的なバス情報フォーマット登録',
  'settings.add.office_text': 'ダイヤ登録前に必ず登録してください。登録後の編集も可能です。',
  'settings.add.bus_text1': '既定のバスフォーマットに沿って運行を計画するための設定です。',
  'settings.add.bus_text2': '標準的なバス情報フォーマットのファイルで一括登録も可能です。',
  'settings.add.single_add': '個別登録',
  'settings.add.all_add': '一括登録（標準的なバス情報フォーマット）',

  /* 系統一覧画面 */
  'parent_routes.title': '系統一覧画面',
  'parent_routes.edit_title': '系統編集',
  'parent_routes.description1': '登録した停留所を使用して、系統の管理・閲覧ができます。',
  'parent_routes.description2': '追加した系統をクリックすると経路、便の管理・閲覧ができます。',
  'parent_routes.route_number': '系統番号',
  'parent_routes.abbreviation': '系統略称',
  'parent_routes.official_name': '系統正式名',
  'parent_routes.forwarding_flag': '運転形式',
  'parent_routes.description': '系統説明文',
  'parent_routes.color': '系統カラー',
  'parent_routes.font_color': '系統文字色',
  'parent_routes.startpoint': '始点',
  'parent_routes.endpoint': '終点',
  'parent_routes.via': '経由標柱',
  'parent_routes.route_list': '経路一覧へ',
  'parent_routes.to_route_button': '経路一覧画面',
  'parent_routes.message.require_abbreviation': '系統略称は必須です',
  'parent_routes.message.max_length_abbreviation': '系統略称は50桁以下で入力してください',
  'parent_routes.message.max_length_official_name': '系統正式名は128桁以下で入力してください',
  'parent_routes.message.max_length_description': '系統説明文は256桁以下で入力してください',
  'parent_routes.message.require_startpoint': '始点は必須です',
  'parent_routes.message.require_endpoint': '終点は必須です',
  'parent_routes.message.require_busstop': '入力は必須です',
  'parent_routes.message.busstop_not_exist': '存在しない標柱名が入力されています',

  /* 経路一覧画面 */
  'routes.title': '経路一覧画面',
  'routes.edit_title': '経路設定',
  'routes.description1': '登録した系統を使用して、経路の管理・閲覧ができます。',
  'routes.description2': '追加した経路をクリックすると便の管理・閲覧ができます。',
  'routes.button.set_trip': '便を設定する',
  'routes.button.reverse_trip': '経路を逆にする',
  'routes.parent_route': '系統番号',
  'routes.abbreviation': '系統略称',
  'routes.official_name': '系統正式名',
  'routes.route_number': '経路番号',
  'routes.route_name': '経路名称',
  'routes.section': '区間',
  'routes.via': '経由',
  'routes.busstops': '停車標柱',
  'routes.notice': '方向幕ID',
  'routes.local_route_id': 'ローカルルートID',
  'routes.notice_busstop': '通知標柱',
  'routes.notice_local_route_id': '通知',
  'routes.notice_local_route_id_yes': 'する',
  'routes.notice_local_route_id_no': 'しない',
  'routes.start_point': '始点',
  'routes.end_point': '終点',
  'common.route.edit': '経路を設定する',
  'routes.message.inconsistency_busstops': '停車標柱が系統と不整合を起こしています',
  'routes.message.require_route_number': '経路番号は必須です',
  'routes.message.max_length_route_number': '経路番号は50桁以下で入力してください',
  'routes.message.require_route_name': '経路名称は必須です',
  'routes.message.max_length_route_name': '経路名称は128桁以下で入力してください',
  'routes.message.least_busstops': '経路は最低2点間を通るようにしてください',

  'routes.message.is_hankaku_notice': '方向幕IDは半角英数字のみ入力できます',
  'routes.message.max_length_notice': '方向幕IDは8桁以下で入力してください',
  'routes.message.is_hankaku_local_route_id': 'ローカルルートIDは半角英数字のみ入力できます',
  'routes.message.max_length_local_route_id': 'ローカルルートIDは8桁以下で入力してください',

  /* 便設定画面 */
  'trips.title': '便設定',
  'trips.edit_title': '便設定',
  'trips.button.add_trip': '便を追加する',
  'trips.button.add_trip-2': '経路に便を追加する事ができます。',
  'trips.route_id': '運行区分',
  'trips.header.route_number': '経路',
  'trips.header.route_name': '',
  'trips.next_day': '翌',
  'trips.arr': '着',
  'trips.arr_start': '着(始)',
  'trips.dep': '発',
  'trips.dep_end': '発(終)',
  'trips.stoppage_time': '停車時間',
  'trips.trip': '便',
  'trips.trip_category': '便区分',
  'trips.trip_number': '便番号',
  'trips.trip_name': '便名',
  'trips.new_trip': '新規の便',
  'trips.start_time': '始発時間',
  'trips.end_time': '終着時間',
  'trips.interval': '運行間隔（分）',
  'trips.message': 'メッセージ',
  'trips.alert': '警告メッセージ',
  'trips.error': 'エラーメッセージ',
  'trips.switch.all': '全入力',
  'trips.switch.start_end': '始終入力',
  'trips.input.header': '発着時刻',
  'trips.input_required': '下記必須入力',
  'trips.input_Departure': '発着時間は必須です',
  'trips.patrol_info': '循環情報',
  'trips.trip.select_date-none': '選択なし',
  'trips.trip.select_date-all': '全て',
  'trips.trip.select_date-1': '平日（月〜金）',
  'trips.trip.select_date-2': '平日（月〜土）',
  'trips.trip.select_date-3': '土曜',
  'trips.trip.select_date-4': '日曜',
  'trips.trip.select_date-5': '祝日',
  'trips.trip.select_date-6': '日曜・祝日',
  'trips.trip.select_date-7': '土曜・日曜',
  'trips.trip.select_date-8': '土曜・日曜・祝日',
  'trips.trip.select_date-9': '全日',
  'trips.message.require_trip_number_name': '便名は必須です',
  'trips.message.max_length_trip_number_name': '便名は50桁以下で入力してください',
  'trips.message.inconsistency_trip': '不整合があります',
  'trips.message.arr_before_previous':
    '[到着時刻] 一つ前の標柱の発車時刻よりも前の時刻が指定されています',
  'trips.message.dep_before_arr': '[発車時刻] 到着時刻よりも前の時刻が指定されています',
  'trips.message.require_start_time': '始発時間は必須です',
  'trips.message.is_hankaku_start_time': '始発時間は半角英数字のみ入力できます',
  'trips.message.require_end_time': '終着時間は必須です',
  'trips.message.is_hankaku_end_time': '終着時間は半角英数字のみ入力できます',
  'trips.message.require_interval': '運行間隔（分）は必須です',
  'trips.message.is_hankakusuji_interval': '運行間隔（分）は半角数字のみ入力できます',

  /* 車両一覧画面 */
  'vehicles.title': '車両',
  'vehicles.edit_title': '車両詳細',
  'vehicles.discription1': 'Dispatcherと接続する車両の管理、閲覧ができます。',
  'vehicles.vendor': 'メーカー',
  'vehicles.numberplate': 'ナンバー',
  'vehicles.numberplate.placeholder': 'すべて入力してください',
  'vehicles.status': '状態',
  'vehicles.status.normal': '正常',
  'vehicles.status.testing': 'テスト運転',
  'vehicles.status.3_month_inspection': '3か月点検中',
  'vehicles.status.vehicle_inspection': '車検中',
  'vehicles.status.sold': '売却',
  'vehicles.status.scrapped': '廃車',
  'vehicles.status.error': 'エラーあり',
  'vehicles.status.unusual': '異常',
  'vehicles.shuttle_id': '管理番号',
  'vehicles.shuttle_id.placeholder': '一意性のある番号を入力',
  'vehicles.shuttle_name': '車両名',
  'vehicles.section': '所属営業所',
  'vehicles.alert': 'アラート',
  'vehicles.alert.yes_or_no': '<br />有無',
  'vehicles.travel_distance': '走行距離(km)',
  'vehicles.size': 'タイプ',
  'vehicles.size.small': '小型',
  'vehicles.size.medium': '中型',
  'vehicles.size.large': '大型',
  'vehicles.size.articulated': '連節',
  'vehicles.size.others': 'その他',
  'vehicles.purchase_date': '車両購入日',
  'vehicles.capacity': '最大乗客数',
  'vehicles.wheelchair': '車いす乗車可否',
  'vehicles.bicycle': '自転車乗車可否',
  'vehicles.vehicle_tel': '電話番号',
  'vehicles.vehicle_tel.placeholder': '例:09012345678※ハイフン無し',
  'vehicles.message.require_shuttle_name': '車両名は必須です',
  'vehicles.message.max_length_shuttle_name': '車両名は50桁以下で入力してください',
  'vehicles.message.require_shuttle_id': '管理番号は必須です',
  'vehicles.message.max_length_shuttle_id': '管理番号は16桁以下で入力してください',
  'vehicles.message.is_hankaku_shuttle_id': '管理番号は半角英数字のみ入力できます',
  'vehicles.message.require_status': '状態は必須です',
  'vehicles.message.require_office': '所属営業所は必須です',
  'vehicles.message.max_length_travel_distance': '走行距離は8桁以下で入力してください',
  'vehicles.message.millage_travel_distance': '走行距離は小数点1位までの数値を入力してください',
  'vehicles.message.max_length_vendor': 'メーカーは50桁以下で入力してください',
  'vehicles.message.require_size': 'タイプは必須です',
  'vehicles.message.max_length_purchase_date': '車両購入日は8桁以下で入力してください',
  'vehicles.message.is_hankaku_purchase_date': '車両購入日は半角英数字のみ入力できます',
  'vehicles.message.format_purchase_date': '車両購入日はYYYYMMDDの形式で入力してください',
  'vehicles.message.require_capacity': '最大乗客数は必須です',
  'vehicles.message.max_length_capacity': '最大乗客数は3桁以下で入力してください',
  'vehicles.message.is_hankaku_capacity': '最大乗客数は半角英数字のみ入力できます',
  'vehicles.message.max_length_numberplate':
    'ナンバープレートは小文字換算で16文字以下で入力してください',
  'vehicles.message.max_length_vehicle_tel': '電話番号は16桁以下で入力してください',
  'vehicles.message.is_hankaku_vehicle_tel':
    '電話番号は数値かハイフンもしくはプラスのみ入力できます',
  'vehicles.service_state.out_of_service': '回送',

  /* 社員一覧画面 */
  'employees.title': '社員一覧',
  'employees.edit_title': '社員詳細',
  'employees.description1': 'Dispatcherを使用する社員の情報になります。',
  'employees.description2': '管理、閲覧ができます。',
  'employees.employee_id': '社員番号',
  'employees.employee_id.placeholder': '所属企業の社員番号を入力',
  'employees.name': '氏名',
  'employees.first_name': '名',
  'employees.last_name': '姓',
  'employees.employ_name': '社員名',
  'employees.mail_address': 'メールアドレス',
  'employees.phone_number': '電話番号',
  'employees.phone_number.placeholder': '例:090-1234-5678',
  'employees.status': 'ステータス',
  'employees.status.normal': '通常',
  'employees.status.training': '研修期間中',
  'employees.status.loa': '休職中',
  'employees.status.retired': '退職',
  'employees.section': '所属営業所名',
  'employees.role': '職種種別',
  'employees.administrator': '管理者',
  'employees.driver': '{0}',
  'employees.supervisor': '運行管理者',
  'employees.supervisor.subname': '(社員管理権限有り)',
  'employees.engineer': '車両管理者',
  'employees.shift_manager': 'ダイヤ・行路管理者',
  'employees.reset_button': 'パスワードリセット',
  'employees.message.require_employee_id': '社員番号は必須です',
  'employees.message.max_length_employee_id': '社員番号は16桁以下で入力してください',
  'employees.message.is_hankaku_employee_id': '社員番号は半角英数字のみ入力できます',
  'employees.message.require_last_name': '氏は必須です',
  'employees.message.max_length_last_name': '氏は50桁以下で入力してください',
  'employees.message.require_first_name': '名は必須です',
  'employees.message.max_length_first_name': '名は50桁以下で入力してください',
  'employees.message.require_mail_address': 'メールアドレスは必須です',
  'employees.message.max_length_mail_address': 'メールアドレスは64桁以下で入力してください',
  'employees.message.is_hankaku_mail_address': 'メールアドレスは半角英数字のみ入力できます',
  'employees.message.no-mach': 'メールアドレスのフォーマットが間違っています',
  'employees.message.require_phone_number': '電話番号は必須です',
  'employees.message.max_length_phone_number': '電話番号は16桁以下で入力してください',
  'employees.message.is_hankakusuji_phone_number': '電話番号は半角英数字のみ入力できます',
  'employees.message.require_status': 'ステータスは必須です',
  'employees.message.reset_password': 'このユーザのパスワードをリセットします。よろしいですか？',
  'employees.message.not_retired': 'ステータスを「退職」に更新してから削除してください',
  'employees.message.require_contact': '連絡先は必須です',
  'employees.message.user_name': '登録されているメールアドレスに、\nユーザー名を再送信',
  'employees.message.password': '登録されているメールアドレスに、\n仮パスワードを送信',
  'employees.message.require_office': '営業所は必須です',

  'employees.message.auth.is_observer':
    '【対象者】オペレーター/管理者/遠隔監視者\n点検登録やアラート対応可能',
  'employees.message.auth.is_controller':
    '【対象者】オペレーター/管理者/遠隔監視者\n車内通話や車両発車、\nディスプレイ操作など車両制御が可能\n※1営業所、10人のみ',
  'employees.message.auth.is_service_manager':
    '【対象者】オペレーター/管理者/遠隔監視者\n車両アサインや事業者・営業所・点検プリセットの\n編集が可能',
  'employees.message.auth.is_setupper':
    '【対象者】管理者\n運行・車両・社員情報などの初期設定が可能',
  'employees.message.auth.is_site_manager': '【対象者】オペレーター/管理者\n点検アプリの使用が可能',
  'employees.message.auth.can_access_personal_info':
    '【対象者】オペレーター/管理者/遠隔監視者\n個人情報に関わる映像や各種設定ページ、\n社員情報の確認が可能',

  'employees.auth.is_observer': '点検/アラート対応',
  'employees.auth.is_controller': '車内通話/車両制御指示',
  'employees.auth.is_service_manager': '運用設定',
  'employees.auth.is_setupper': '初期設定',
  'employees.auth.is_site_manager': '車両機器確認',
  'employees.auth.can_access_personal_info': '個人情報',
  'employees.edit.username': 'ユーザー名',
  'employees.edit.password': 'パスワード',
  'employees.title.usename_password': 'ユーザー名 / パスワード',
  'employees.title.auth': '権限種別',
  'employees.title.other': 'その他情報（添付ファイル・通知アラート）',
  'employees.auth.send_username': '再送信',
  'employees.auth.send_password': 'リセット',
  'employees.message.reload': '車内通話/車両制御指示が更新されたためDispatcherをリロードします',

  /* 営業所一覧画面 */
  'offices.title': '営業所',
  'offices.edit_title': '営業所詳細',
  'offices.button.detail': '詳細',
  'offices.description1': 'Dispatcherを使用する営業所の情報になります。',
  'offices.description2': '管理、閲覧ができます。',
  'offices.section': '営業所名',
  'offices.postal_code': '郵便番号',
  'offices.address': '住所',
  'offices.phone_number': '電話番号',
  'offices.latitude': '営業所緯度',
  'offices.longitude': '営業所経度',
  'offices.message.require_section': '営業所名は必須です',
  'offices.message.max_length_section': '営業所名は50桁以下で入力してください',
  'offices.message.is_hankakusuji_postal_code': '郵便番号は半角数字のみ入力できます',
  'offices.message.max_length_postal_code': '郵便番号は8桁以下で入力してください',
  'offices.message.max_length_address': '住所は100桁以下で入力してください',
  'offices.message.max_length_phone_number': '電話番号は16桁以下で入力してください',
  'offices.message.is_hankakusuji_phone_number': '電話番号は半角数字のみ入力できます',
  'offices.message.max_length_latitude': '営業所緯度は32桁以下で入力してください',
  'offices.message.is_hankaku_latitude': '営業所緯度は半角英数字のみ入力できます',
  'offices.message.max_length_longitude': '営業所経度は32桁以下で入力してください',
  'offices.message.is_hankaku_longitude': '営業所経度は半角英数字のみ入力できます',
  'offices.roll_call_header_title': '点検設定',
  'offices.roll_call': '点呼',

  /* 事業者情報画面 */
  'agency.title': '事業者情報編集',
  'agency.discription': 'Dispatcherを使用する事業者情報を登録してください。',
  'agency.company_name': '事業者名称',
  'agency.official_name': '事業者正式名称',
  'agency.mail_address': '事業者メールアドレス',
  'agency.postal_code': '郵便番号',
  'agency.postal_code.placeholder': '例:0000000　※ハイフンなし',
  'agency.address': '住所',
  'agency.latitude': '緯度',
  'agency.longitude': '経度',
  'agency.phone_number': '電話番号',
  'agency.phone_number.placeholder': '例:03-1234-5678',
  'agency.representative_name': '代表者氏名',
  'agency.representative_title': '代表者肩書',
  'agency.message.require_company_name': '事業者名称は必須です',
  'agency.message.max_length_company_name': '事業者名称は50桁以下で入力してください',
  'agency.message.require_official_name': '事業者正式名称は必須です',
  'agency.message.max_length_official_name': '事業者正式名称は50桁以下で入力してください',
  'agency.message.require_mail_address': '事業者メールアドレスは必須です',
  'agency.message.max_length_mail_address': '事業者メールアドレスは64桁以下で入力してください',
  'agency.message.is_hankaku_mail_address': '事業者メールアドレスは半角英数字のみ入力できます',
  'agency.message.no-mach': '事業者メールアドレスはのフォーマットが間違っています',
  'agency.message.max_length_postal_code': '郵便番号は8桁以下で入力してください',
  'agency.message.is_hankakusujihyphen_postal_code': '郵便番号は半角数字とハイフンのみ入力できます',
  'agency.message.max_length_address': '住所は100桁以下で入力してください',
  'agency.message.max_length_latitude': '緯度は32桁以下で入力してください',
  'agency.message.is_hankaku_latitude': '緯度は半角英数字のみ入力できます',
  'agency.message.max_length_longitude': '経度は32桁以下で入力してください',
  'agency.message.is_hankaku_longitude': '経度は半角英数字のみ入力できます',
  'agency.message.max_length_phone_number': '電話番号は16桁以下で入力してください',
  'agency.message.is_hankakusuji_phone_number': '電話番号は半角数字のみ入力できます',
  'agency.message.require_representative_name': '代表者氏名は必須です',
  'agency.message.max_length_representative_name': '代表者氏名は50桁以下で入力してください',
  'agency.message.require_representative_title': '代表者肩書は必須です',
  'agency.message.max_length_representative_title': '代表者肩書は50桁以下で入力してください',

  /* 点検プリセット登録画面 */
  'check_presets.office': '点呼',
  'check_presets.area': 'ODD',
  'check_presets.vehicle': '車両機器',
  'check_presets.title': '点検プリセット登録',
  'check_presets.description': '運行時の点検項目を登録できます',
  'check_presets.update_date': '登録日',
  'check_presets.target': 'カテゴリー',
  'check_presets.preset_name': 'プリセット名',
  'check_presets.update_employee': '登録者',
  'check_presets.goto_offices': '営業所設定',
  'check_presets.goto_areas': 'エリア設定',
  'check_presets.goto_courses': '行路設定',
  'check_presets.goto_vehicles': '車両設定',
  'check_presets.message1.office': '点呼に使用するプリセット登録ができます。',
  'check_presets.message2.office': '点呼プリセットは営業所の設定から設定できます。',
  'check_presets.message2.office.replace_key1': '営業所',
  'check_presets.message1.area': 'ODDに使用するプリセットを作成することができます。',
  'check_presets.message2.area': 'プリセットは行路やエリアの設定画面から設定できます。',
  'check_presets.message2.area.replace_key1': '行路',
  'check_presets.message2.area.replace_key2': 'エリア',
  'check_presets.message1.vehicle': '車両機器に使用するプリセットを作成することができます。',
  'check_presets.message2.vehicle': 'プリセットは車両の設定画面から設定できます。',
  'check_presets.message2.vehicle.replace_key1': '車両',
  'check_presets.validation.max_length_50': '50桁以下で入力してください',
  'check_presets.validation.required': '入力が必須です',
  'check_presets.place_holder': '入力して下さい',

  /* 停留所一覧画面 */
  'busstops.title': '停留所一覧画面',
  'busstops.edit_title': '停留所詳細',
  'busstops.edit': '編集',
  'busstops.description1': '停留所の管理・閲覧ができます。',
  'busstops.description2': '停留所は系統画面などで使用できます。',
  'busstops.search': 'キーワード検索',
  'busstops.stop_code': '停留所番号',
  'busstops.stop_name': '停留所名',
  'busstops.stop_add_name': '標柱名を入力',
  'busstops.stop_kana': '停留所名(ひらがな)',
  'busstops.stop_english': '停留所名(英字)',
  'busstops.stop_desc': '停留所付加情報',
  'busstops.stop_lat': '緯度',
  'busstops.stop_lon': '経度',
  'busstops.zoneId': '運賃エリアID',
  'busstops.pole_code': '標柱番号',
  'busstops.pole_name': '標柱名',
  'busstops.pole_kana': '標柱名(ひらがな)',
  'busstops.pole_english': '標柱名(英字)',
  'busstops.pole_desc2': '標柱付加情報',
  'busstops.pole_lat': '標柱緯度',
  'busstops.pole_lon': '標柱経度',
  'busstops.pole_lat_range': '緯度レンジ',
  'busstops.pole_lon_range': '経度レンジ',
  'busstops.pole_yaw': 'ヨー角',
  'busstops.pole_yaw_range': 'ヨー角レンジ',
  'busstops.pole_japanese_pronunciation': '発話(日文)',
  'busstops.pole_english_pro': '発話(英文)',
  'busstops.button.add': '追加',
  'busstops.button.add_pole': '標柱登録',
  'busstops.button.del_pole': '標柱削除',
  'busstops.added_pole': '標柱',
  'busstops.pole_with': '標柱有無',
  'busstops.hexadecimal': '16進数',
  'busstops.map': '地図',
  'busstops.map.show': '地図を表示',
  'busstops.map.edit': '地図から入力',

  'busstops.map.desc': 'の位置を地図から選択し入力することができます。',

  'busstops.message.add_pole_over_20': '標柱の登録は20件までです',
  'busstops.message.require_stop_code': '停留所番号は必須です',
  'busstops.message.max_length_stop_code': '停留所番号は50桁以下で入力してください',
  'busstops.message.require_stop_name': '停留所名は必須です',
  'busstops.message.max_length_stop_name': '停留所名は50桁以下で入力してください',
  'busstops.message.max_length_stop_kana': 'ひらがなは256桁以下で入力してください',
  'busstops.message.max_length_stop_english': '英字は256桁以下で入力してください',
  'busstops.message.is_hankaku_stop_english': '英字は半角英数字のみ入力できます',
  'busstops.message.max_length_stop_desc': '停留所付加情報は64桁以下で入力してください',
  'busstops.message.is_hankaku_stop_lat': '停留所緯度は半角英数字のみ入力できます',
  'busstops.message.max_length_stop_lat': '停留所緯度は32桁以下で入力してください',
  'busstops.message.is_hankaku_stop_lon': '停留所経度は半角英数字のみ入力できます',
  'busstops.message.max_length_stop_lon': '停留所経度は32桁以下で入力してください',
  'busstops.message.is_hankaku_zoneId': '運賃エリアIDは半角英数字のみ入力できます',
  'busstops.message.max_length_zoneId': '運賃エリアIDは8桁以下で入力してください',

  'busstops.message.require_pole_code': '標柱番号は必須です',
  'busstops.message.max_length_pole_code': '標柱番号は50桁以下で入力してください',
  'busstops.message.require_pole_name': '標柱名は必須です',
  'busstops.message.max_length_pole_name': '標柱名は50桁以下で入力してください',
  'busstops.message.max_length_pole_kana': 'ひらがなは256桁以下で入力してください',
  'busstops.message.max_length_pole_english': '英字は256桁以下で入力してください',
  'busstops.message.is_hankaku_pole_english': '英字は半角英数字のみ入力できます',
  'busstops.message.max_length_pole_desc': '標柱付加情報は64桁以下で入力してください',
  'busstops.message.is_hankaku_pole_lat': '標柱緯度は半角英数字のみ入力できます',
  'busstops.message.max_length_pole_lat': '標柱緯度は32桁以下で入力してください',
  'busstops.message.is_hankaku_pole_lon': '標柱経度は半角英数字のみ入力できます',
  'busstops.message.max_length_pole_lon': '標柱経度は32桁以下で入力してください',
  'busstops.message.is_hankaku_pole_lat_range': '標柱緯度のレンジは半角英数字のみ入力できます',
  'busstops.message.max_length_pole_lat_range': '標柱緯度のレンジは16桁以下で入力してください',
  'busstops.message.is_hankaku_pole_lon_range': '標柱経度のレンジは半角英数字のみ入力できます',
  'busstops.message.max_length_pole_lon_range': '標柱経度のレンジは16桁以下で入力してください',
  'busstops.message.is_hankaku_pole_yaw': 'ヨー角は半角英数字のみ入力できます',
  'busstops.message.max_length_pole_yaw': 'ヨー角は4桁以下で入力してください',
  'busstops.message.is_hankaku_pole_yaw_range': 'ヨー角のレンジは半角英数字のみ入力できます',
  'busstops.message.max_length_pole_yaw_range': 'ヨー角のレンジは4桁以下で入力してください',
  'busstops.message.within_range.lat': '-90から90の範囲で設定してください',
  'busstops.message.within_range.lon': '-180から180の範囲で設定してください',
  'busstops.message.within_range.yaw': '0から360の範囲で設定してください',
  'busstops.message.within_range.yaw_range': '0から180の範囲で設定してください',
  'busstops.message.within_range.range': '0から30の範囲で設定してください',
  'busstops.message.bad.input': 'の入力値が不正です。',

  'busstops.cascade.deleted': '削除されました',
  'busstops.message.cascade.influence':
    '削除された標柱があります。このまま登録した場合、行路に影響が出る可能性があります。',

  'busstops.dropzone.title': 'バス停画像登録',
  'busstops.dropzone.info1': '画像の登録・差し替えは',
  'busstops.dropzone.info2': 'クリックもしくはドラッグ＆ドロップ',

  /* 行路一覧画面 */
  'course.title': '行路一覧画面',
  'course.edit_title': '行路詳細',
  'course.description1': '行路を管理・閲覧できます。',
  'course.edit': '行路情報編集画面',
  'course.course_number': '行路番号',
  'course.service_id': '運行区分',
  'course.route_id': '通行区分',
  'course.operation_type': '運行種別',
  'course.course_name': '行路名',
  'course.total_break_time': '合計休憩時間(分)',
  'course.button.add': '追加',
  'course.nothing_trip': '登録されている便がありません',
  'course.button.set_trip': '便を設定する',
  'course.unnamed': '名称未設定',
  'course.mission_possible': '自動発車指示機能',
  'course.mission_possible.yes': 'ON',
  'course.mission_possible.no': 'OFF',
  'course.mission_vehicle': '自動発車車両',

  'course.operation-type-dialog.text1': '運行種別を切り替えたため',
  'course.operation-type-dialog.text2': '追加されている便が削除されます。',
  'course.operation-type-dialog.text3': 'よろしいですか？',

  'course.trip.add': '便の追加',
  'course.button.course_edit': '行路編集',
  'course.button.add_edit': '行路に便を追加する事ができます。',

  'course.trip.select_date-1': '平日（月〜金）',
  'course.trip.select_date-2': '平日（月〜土）',
  'course.trip.select_date-3': '土曜',
  'course.trip.select_date-4': '日曜',
  'course.trip.select_date-5': '祝日',
  'course.trip.select_date-6': '日曜・祝日',
  'course.trip.select_date-7': '土曜・日曜',
  'course.trip.select_date-8': '土曜・日曜・祝日',
  'course.trip.select_date-9': '全日',

  'course.message.max_length_course_number': '行路番号は50桁以下で入力してください',
  'course.message.require_course_number': '行路番号は必須です',
  'course.message.require_service_id': '運行区分は必須です',
  'course.message.operation_type': '運行種別は必須です',
  'course.message.max_length_course_name': '行路名は128桁以下で入力してください',
  'course.message.require_course_name': '行路名は必須です',
  'course.message.date_error': 'YYYYMMDDの形式で入力して下さい。',

  /* 行路編集画面 */
  'courseedit.title': '行路設定',
  'courseedit.header': '行路・',
  'courseedit.sub-title': '系統区分',
  'courseedit.next': '翌',
  'courseedit.arr': '着',
  'courseedit.dep': '発',
  'courseedit.trip': '便・',
  'courseedit.tripcode-tripname': '便名',
  'courseedit.parent_route': '系統',
  'courseedit.route': '経路',
  'courseedit.timezone': '時間帯',

  'courseedit.button.add_course': 'この行路に便を追加する',
  'courseedit.button.search': '検索する',
  'courseedit.button.search_result': '検索結果',
  'courseedit.start_time': '開始時間',
  'courseedit.end_time': '終了時間',
  'courseedit.interval': '運行間隔',
  'courseedit.expiration_date-1': '有効期限（開始日）',
  'courseedit.expiration_date-2': '有効期限（終了日）',

  'courseedit.message.cascade': '選択したデータは不正な場合があります。',

  /* エリア設定 */
  'area.title': 'エリア設定',
  'area.edit_title': 'エリア詳細',
  'area.description': '配車バスで使用する、エリアの設定をする事ができます。',
  'area.area_name': 'エリア名称',
  'area.area_name.message.require_area_name': '入力は必須です',
  'area.area_name.message.max_length_area_name': 'エリア名称は??桁以下で入力してください',
  'area.from_to': '運行期間',
  'area.area_description': 'エリア説明',
  'area.area_description.message.max_length_area_description':
    'エリア説明は??桁以下で入力してください',
  'area.from_date': '有効期限（開始日）',
  'area.to_date': '有効期限（終了日）',
  'area.from_time': '開始時間',
  'area.to_time': '終了時間',
  'area.service_id': '運行区分',
  'area.office_accodion': '対象営業所',
  'area.office': '営業所',
  'area.rollCall': 'ODD',
  'area.rollCall.require_rollCall': '入力は必須です',
  'area.rollCall.add_rollCall': '点呼設定',
  'area.busstops_accodion': 'バス停一覧',
  'area.busstops': 'バス停',
  'area.busstops.require_busstops': '入力は必須です',
  'area.vehicles_accodion': '対象車両',
  'area.vehicles': '車両',
  'area.line_accodion': 'LINE予約制御',
  'area.line.radio_1': '全日程',
  'area.line.radio_2': 'カスタム',
  'area.line.text_1': 'から',
  'area.line.text_2': 'まで',
  'area.line.text_3': '分前から',
  'area.line.text_4': '分前まで',
  'area.line.open': 'OPEN',
  'area.line.close': 'CLOSE',
  'area.line.start': '走行開始時刻',
  'area.line.hour': '時間',
  'area.line.day': '日',
  'area.abs_time.error': 'カスタムの日付データが不正です',
  'area.hover_text.line': 'LINEアカウントを発行後、定時便予約が入った時のみ有効です。',

  /* インポート */
  'import.title': 'ファイル読み込み',
  'import.dropzone.title': 'バス標準フォーマット',
  'import.dropzone.info2': 'クリックもしくはドラッグ&ドロップ',

  /* アラート一覧 */
  'alert.title': 'アラート一覧',
  'alert.edit': 'アラート詳細',

  'alert.status': 'ステータス',
  'alert.status.not_compatible': '未対応',
  'alert.status.during_complete': '対応中',
  'alert.status.complete': '対応完了',
  'alert.status.not_updated': '未更新',
  'alert.status.finish_date': '終了日時',
  'alert.status.flag': 'フラグON/OFF',
  'alert.alert_level': 'レベル',
  'alert.alert_type.placeholder': 'アラートコードを入力してください',
  'alert.alert_number': '番号',
  'alert.alert_number2': '番号',
  'alert.alert_title': 'タイトル',
  'alert.alert_category_name': 'カテゴリ',
  'alert.map': '地図',
  'alert.occuring.location': 'アラート発生位置',
  'alert.event_date': '発生日時',
  'alert.update_date': '更新日時',
  'alert.last_update': '最終更新',
  'alert.event_vehicle_name': '発生車両',
  'alert.all_vehicles': '全ての車両',
  'alert.vehicle': '車両',
  'alert.owner_employee': '担当者',
  'alert.owner_all_employee': 'すべて',
  'alert.owner_employee_name': '担当者名',
  'alert.alert_code': '種別',
  'alert.alert_code.placeholder': 'アラートコードを入力してください',
  'alert.flag': 'フラグ',
  'alert.alert_detail': '詳細内容',
  'alert.error_code': 'エラー<br />コード',
  'alert.event_course_name': '発生行路',
  'alert.comment': 'コメント',
  'alert.comment_add': 'コメントを入力してください',
  'alert.case': '件',
  'alert.search': '反映',
  'alert.comment.register_date': '登録日時',
  'alert.comment.employee_name': '投稿者',
  'alert.detailed_information': '緯度経度コピー',
  'alert.updatehistory': '更新内容',
  'alert.updatehistory.register_date': '登録日時',
  'alert.updatehistory.employee_name': '投稿者',
  'alert.aletlevel.information': 'INFO',
  'alert.aletlevel.warning': 'WARNING',
  'alert.ignore_analysis': '分析ページ表示',
  'alert.ignore_analysis.visible': '表示',
  'alert.ignore_analysis.hidden': '非表示',
  'alert.date': [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  'alert.select_date': ['今週', '今月', '先月', '先々月', 'カスタム'],
  'alert.select_date_full_term': '全期間',

  'alert.message.input_ValidationError': 'コメント、ステータス、担当者のいずれかを更新してください',
  'alert.message.require_status': 'ステータスは必須です',
  'alert.message.max_length_comment': 'コメントは256桁以下で入力してください',
  'alert.message.require_event_date': '発生日時は必須です',
  'alert.import.message': '画像を',
  'alert.import.label': '画像',

  /* 点呼履歴画面 */
  'roll-history.title': '点呼履歴',
  'roll-history.event_date': '走行日',
  'roll-history.office': '営業所',
  'roll-history.owner_all_office': 'すべて',
  'roll-history.owner_employee': '担当者',
  'roll-history.owner_all_employee': 'すべて',
  'roll-history.Vehicle': '車両',
  'roll-history.owner_all_Vehicle': 'すべて',
  'roll-history.search': '検索',

  'roll-history.running-day': '走行日',
  'roll-history.operational-use': '用途',
  'roll-history.area-course': 'エリア・行路',
  'roll-history.pre-operation-inspection': '運行前点検',
  'roll-history.pre-operation-inspection-registrant': '運行前点検 登録者',
  'roll-history.inspection-after-operation': '運行後点検',
  'roll-history.inspection-after-operation-registrant': '運行後点検 登録者',

  /* 点呼チェック登録画面 */
  'roll-call-check.no-data': '登録されておりません。',
  'roll-call-check.running-before': '運行前',
  'roll-call-check.running-after': '運行後',
  'roll-call-check.header-text1':
    '必ず全ての項目をチェックし、登録しなければ走行する事はできません。',
  'roll-call-check.header-text2':
    '全ての項目がチェックされていない場合、走行不可として登録することもできます。',
  'roll-call-check.roll-call': '点呼',
  'roll-call-check.odd': 'ODD',
  'roll-call-check.vehicle-equipment': '車両機器',
  'roll-call-check.vehicle-equipment.message':
    '車両機器の点検は専用のアプリケーションをご使用下さい',
  'roll-call-check.update_employee': '登録者',
  'roll-call-check.update-date': '登録日時',

  'roll-call-check.remarks': '※特記事項記入',
  'roll-call-check.operator': 'オペレーター',

  /* アラートマップ */
  'alertmap.title': 'アラートマップ',
  'alertmap.detail-title': '表示アラート設定',
  'alertmap.detail-event_date': '発生日時',
  'alertmap.detail-event_date-format': 'YYYY/MM/DD HH:mm:ss',
  'alertmap.detail-alert_number': 'アラート<br />番号',
  'alertmap.detail-event_course_name': '発生行路',
  'alertmap.detail-event_vehicle_name': '発生車両',
  'alertmap.all_vehicles': '全ての車両',
  'alertmap.detail-alert_type': 'アラート<br />種別',
  'alertmap.event_date-format': 'MM月DD日 HH時mm分ss秒',
  'alertmap.alert-type-incaralarm': '転倒',
  'alertmap.alert-type-drivingstandup': '走行中移動',
  'alertmap.alert-type-safety-before-auto-driving': '着座前発進',
  'alertmap.feature-title1': '日時',
  'alertmap.feature-title2': '種別',
  'alertmap.low-accuracy-message': 'GPS精度:低',
  'alertmap.congestion_rate': '混雑状況',

  'alertmap.ignore_analysis.hidden': '非表示',
  'alertmap.ignore_analysis.cancel': '取消',
  'alertmap.options-week': '今週',
  'alertmap.options-month': '今月',
  'alertmap.options-1_month_ago': '前月',
  'alertmap.options-2_month_ago': '前々月',
  'alertmap.options-morning': '朝',
  'alertmap.options-noon': '昼',
  'alertmap.options-night': '夜',
  'alertmap.setting': '詳細設定',
  'alertmap.frequency': 'アラート種別',
  'alertmap.times': '回',
  'alertmap.alert_sum_times': 'アラート内容(合計数値)',
  'alertmap.alert_sum_times_caption': '※回数表示',
  'alertmap.accrual_date': '対象日',
  'alertmap.accrual_period': '対象期間',
  'alertmap.drivers': '{0}',
  'alertmap.all_drivers': '全ての{0}',
  'alertmap.options-acceleration': '急発進・急加速',
  'alertmap.options-deceleration': '急停止・急減速',
  'alertmap.options-steering': '急旋回',
  'alertmap.options-overspeed': '速度オーバー',
  'alertmap.options-idling': 'アイドリング',
  'alertmap.options-standup_judgement_moving': '走行中移動',
  'alertmap.options-safety-before-auto-driving': '着座前発進',
  'alertmap.options-turnover': '転倒',
  'alertmap.options-title-maptype': 'ポイント/ヒートマップ切り替え',
  'alertmap.options-title-dateperiod': '表示期間',
  'alertmap.options-title-timeperiod': '表示時間',
  'alertmap.options-title-alerttype': 'アラートタイプ',
  'alertmap.heat_map': 'ヒートマップ',
  'alertmap.path': '行路',
  'alertmap.all_path': '全ての行路',
  'alertmap.set_open': '設定を開く',
  'alertmap.set_close': '設定を閉じる',
  'alertmap.check_alert': 'アラート内容を選択してください',
  'alertmap.message.error_fetch_running_data_list': '運行中車両一覧の取得に失敗しました',
  'alertmap.message.error_fetch_alert_map_data': 'アラートマップの取得に失敗しました',
  'alertmap.message.error_select_trip_by_alert': '経路の取得に失敗しました',
  'alertmap.message.error_get_data-1': '未取得のデータがあります。',
  'alertmap.message.error_get_data-2':
    '再度読み込みして解決しない場合は運行管理者にお知らせください。',
  'alertmap.unknow': '不明なアラート',
  'alertmap.alert-count-label': '対象アラート合計数',
  'alertmap.message.select_all': '全て',
  'alertmap.message.select_all_vehicle': '全車両',
  'alertmap.message.select_all_employee': '全オペレーター',

  'alertmap.tab.issave': '検索条件を保存する',
  'alertmap.tab.export': 'エクスポート',
  'alertmap.tab.csv': 'CSV',
  'alertmap.tab.mileage_chart': '走行距離グラフ',
  'alertmap.tab.driving_time_chart': '走行時間グラフ',
  'alertmap.tab.drive_mode': '運転種別',
  'alertmap.tab.drive_mode.full': '自動・手動運転',
  'alertmap.tab.drive_mode.auto': '自動運転',
  'alertmap.tab.drive_mode.manual': '手動運転',
  'alertmap.tab.drive_mode.stop': '停車中',
  'alertmap.tab.stoptime': '停車中を除く',
  'alertmap.tab.chart_type': 'グラフ',
  'alertmap.tab.chart_type.line': '折れ線グラフ',
  'alertmap.tab.chart_type.bar': '積み上げ棒グラフ',
  'alertmap.tab.chart_type.simple_bar': '棒グラフ',
  'alertmap.tab.unit': '表記',
  'alertmap.tab.unit.km': 'キロメートル',
  'alertmap.tab.unit.hour': '時間',
  'alertmap.tab.unit.percent': 'パーセント',
  'alertmap.tab.vehile_name': '車両名',
  'alertmap.tab.employee_name': '社員名',
  'alertmap.tab.total_mileage': '合計距離',
  'alertmap.tab.total_driving_time': '合計時間',
  'alertmap.tab.remaining_battery': '最終バッテリー残量',
  'alertmap.tab.average_speed_chart': '平均スピードグラフ',
  'alertmap.tab.remaining_battery_chart': 'バッテリー残量グラフ',
  'alertmap.tab.remaining_battery_active_period': '稼働時間',
  'alertmap.tab.alert_map': 'アラートマップ',
  'alertmap.tab.driving_hash_map': '自動手動走行軌跡',

  /* ダイアログ */
  'dialog.delete': '削除しますか？',
  'dialog.delete_Confirmation': 'データを元に戻す事はできません。',
  'dialog.deleted': '削除しました',
  'dialog.move': '移動しますか？',
  'dialog.move_Confirmation': '編集中のデータが破棄されます。',
  'dialog.moved': '移動する',
  'dialog.password_reset': 'リセット',
  'dialog.password_reset_Confirmation': 'パスワードを本当にリセットしますか？',
  'dialog.password_text-1': 'リセットを行うと、リセットしたアカウントに',
  'dialog.password_text-2': 'メールで連絡が届きます。',
  'dialog.send_mail': '送信',
  'dialog.send_username': 'ユーザー名を送信しますか',
  'dialog.send_username_text-1': 'ユーザー名を記載した',
  'dialog.send_username_text-2': 'メールをお送りいたします',
  'dialog.password_reseted': 'リセットが完了しました。',
  'dialog.password_reset_text-1': 'リセットしたアカウントに',
  'dialog.password_reset_text-2': 'メールを送信しました。',
  'dialog.update': '登録しますか？',
  'dialog.updated': '登録しました',
  'dialog.cascade.countmessage': '{0}件の系統に影響がでます。',
  'dialog.cascade.messege1': '削除したデータは元に戻すことはできません。',
  'dialog.cascade.messege2': 'また、他データに影響を出す可能があります。',
  'dialog.notification.parmission_error':
    '通知設定の有効化に失敗しました。\nブラウザの権限設定を確認してください',

  'dialog.copy-lat-lon.message': '緯度経度をコピーしました',
  'dialog.cascade_message1': '不正なデータがあります。',
  'dialog.cascade_message2': '系統画面よりご確認をお願いします。',
  'dialog.cascade_message3': '系統を設定する',

  /* インフォメーション */
  'information.date': '投稿日',
  'information.category': 'カテゴリー',
  'information.title': 'タイトル',
  'information.notice': 'BOLDLYからのお知らせを掲載しています。ご確認ください。',
  'information.notice_date': '発生日',

  /* 車輌一覧・詳細 */
  'running-vehicle.alert_confirmation-1': 'アラート一覧をご覧ください',
  'running-vehicle.alert_confirmation-2': 'アラート一覧',
  'running-vehicle.alert_confirmation-check-1': '未対応のアラートがあります',
  'running-vehicle.alert_confirmation-check-2': 'アラート対応をはじめてください',
  'running-vehicle.alert_me': '対応中のアラートがあります',
  'running-vehicle.alert_me-2': '確認を行ってください',
  'running-vehicle.alert_nothing': 'アラートがありません',
  'running-vehicle.alert_nothing-2': 'アラートが発生すると表示されます',
  'running-vehicle.alert_correspondence-1': '未対応',
  'running-vehicle.alert_correspondence-2': '対応中',
  'running-vehicle.alert_check_me': 'あなた',
  'running-vehicle.alert_check_other': 'あなた以外',
  'running-vehicle.alert_flag': 'フラグON/OFF',
  'running-vehicle.alert_correspond': '対応する',
  'running-vehicle.alert_correspond-text-1': '対応が完了した場合',
  'running-vehicle.alert_correspond-text-2': '対応完了ボタンを押してください',
  'running-vehicle.alert_corresponded': '対応完了',
  'running-vehicle.alert_set_Person_in_charge': '担当者設定',
  'running-vehicle.alert_change_correspond': '対応者変更',
  'running-vehicle.alert_other_text-1': '内容',
  'running-vehicle.alert_other_text-2': '対応方法',
  'running-vehicle.alert-form-title': 'タイトル',
  'running-vehicle.alert-form-title-placeholder': '記入して下さい。',
  'running-vehicle.alert-form-category-placeholder': '選択してください.',
  'running-vehicle.alert-form-detail-placeholder': 'アラートの内容、対応方法を記入して下さい。',
  'running-vehicle.alert-form-map-select': 'アラート発生位置に地図を移動させてください',
  'running-vehicle.alert-form-map-position': 'アラート発生位置',
  'running-vehicle.alert_set_all_check': '同種のアラートも一括で処理',
  'running-vehicle.alert-form-image': '画像',
  'running-vehicle.alert-form-image-message1': 'ここにファイルをドロップ',
  'running-vehicle.alert-form-image-message2': 'または',
  'running-vehicle.alert-form-image-message3': 'ファイルを選択',
  'running-vehicle.alert-form-image-label': '画像',
  'running-vehicle.alert-form-video-label': '映像',
  'running-vehicle.alert-form-map-label-notification': '(位置を指定してください)',
  'running-vehicle.alert-form-title-require-message': 'タイトルは必須です',
  'running-vehicle.alert-form-detail-require-message': '詳細は必須です',
  'running-vehicle.caller-button_connect': '接続中',
  'running-vehicle.caller-button_receive_call': '着信中',
  'running-vehicle.caller-button_disconnect': '通話切断',
  'running-vehicle.caller-button_call': '通話中',
  'running-vehicle.caller-button_busy': '通話不可',
  'running-vehicle.caller-button_inside_car_call': '車内通話',
  'running-vehicle.caller-button_outside_car_call': '車外通話',
  'running-vehicle.caller-button_announcement_car_call': 'アナウンス',
  'running-vehicle.call-disconnect': '通話の接続に失敗しました',

  'running-vehicle.caller-button_text-1': '現在こちらの画面で通話中です。',
  'running-vehicle.caller-button_text-2': '通話を切断してほかの画面に切り替えますか？',
  'running-vehicle.caller-button_move-1': '移動をやめる',
  'running-vehicle.caller-button_move-2': '他の画面に移動する',
  'running-vehicle.discomfort_index': '不快指数',
  'running-vehicle.connection': 'システム接続状況',
  'running-vehicle.door': 'ドア',
  'running-vehicle.driving-mode-1': '（オーバーライド）',
  'running-vehicle.driving-mode-2': '運転モード',
  'running-vehicle.emergency': '緊急',
  'running-vehicle.emergency_connection': '緊急通知中',
  'running-vehicle.emergency_on': '緊急通知ON',
  'running-vehicle.emergency_off': '緊急通知OFF',
  'running-vehicle.emergency_sending': '送信中',
  'running-vehicle.emergency_sending_error': '送信エラー',
  'running-vehicle.emergency_dialog-1': '火災',
  'running-vehicle.emergency_dialog-2': '地震',
  'running-vehicle.emergency_dialog-3': '緊急事態',
  'running-vehicle.emergency_dialog-4': '車両故障',
  'running-vehicle.emergency_dialog-select': '車両ディスプレイ',
  'running-vehicle.emergency_dialog_text-1': '車両ディスプレイに表示をしますか？',
  'running-vehicle.emergency_dialog_text-2': '※車両が走行中の場合は、停車します',
  'running-vehicle.emergency_dialog_text.name': '詳細情報を記入してください',
  'running-vehicle.emergency_dialog_text.placeholder': '20文字以内で入力をしてください',
  'running-vehicle.emergency_dialog_text.error.isrequired': 'メッセージは必須です',
  'running-vehicle.emergency_dialog_text.error.maxlength': '20文字以内で入力してください',
  'running-vehicle.emergency_dialog_yes': 'はい',
  'running-vehicle.emergency_dialog_no': 'いいえ',
  'running-vehicle.emergency_fire': '火災発生',
  'running-vehicle.emergency_earthquake': '地震発生',
  'running-vehicle.emergency_emergency': '停止中',
  'running-vehicle.emergency_failure': '車両故障',
  'running-vehicle.message_level.select': 'カテゴリを選択してください',
  'running-vehicle.message_level.info': 'お知らせ',
  'running-vehicle.message_level.warning': '注意',
  'running-vehicle.message_level.error': '警告',
  'running-vehicle.engine': 'エンジン',
  'running-vehicle.engine_rpm': '回転数',
  'running-vehicle.live-map_layer': 'スタイル地図',
  'running-vehicle.live-map_location': '現在地',
  'running-vehicle.live-map_map': '地図',
  'running-vehicle.multi-view_camera': 'カメラ切り替え',
  'running-vehicle.multi-view_camera.selected': '選択中',
  'running-vehicle.multi-view_camera.blust_view': 'ブラスト確認用',
  'running-vehicle.passengers': '人数',
  'running-vehicle.speed': '速度',
  'running-vehicle.location_delay': '遅延',
  'running-vehicle.location_minute': '分',
  'running-vehicle.location_passenger': '乗客',
  'running-vehicle.location_count': '人',
  'running-vehicle.location_status': '運転状況',
  'running-vehicle.map_type-1': '航空写真',
  'running-vehicle.map_type-2': '地下街',
  'running-vehicle.map': '地図',
  'running-vehicle.status-1': '正常',
  'running-vehicle.status-2': 'アラートあり',
  'running-vehicle.move_to_vehicle': '中央に表示',
  'running-vehicle.list_vehicle': '車両一覧',
  'running-vehicle.list_alert': '車両アラート',
  'running-vehicle.battery': 'バッテリー',
  'running-vehicle.service_mode': 'サービス状態',
  'running-vehicle.service_mode.metro': 'Metro',
  'running-vehicle.service_mode.bus': 'Bus',
  'running-vehicle.service_mode.on_demand': 'On Demand',
  'running-vehicle.vehicle_mode': '車両状態',
  'running-vehicle.vehicle_mode.use': 'Use',
  'running-vehicle.vehicle_mode.standby': 'Standby',
  'running-vehicle.vehicle_mode.safety': 'Safety',
  'running-vehicle.car_temperature': 'ラック内温度',
  'running-vehicle.car_temperature_out': '外気温',
  'running-vehicle.coolant_temperature': '冷却水温',
  'running-vehicle.loading_quantity': '積載量',
  'running-vehicle.hit_ratio': 'ヒットレシオ',
  'running-vehicle.goto_detail': '詳細画面',
  'running-vehicle.interior': '内カメラ',
  'running-vehicle.exterior': '外カメラ',
  'running-vehicle.front_camera': '前方カメラ',
  'running-vehicle.rear_camera': '後方カメラ',
  'running-vehicle.blast': 'ブラスト',
  'running-vehicle.camera': 'カメラ',
  'running-vehicle.option': 'オプション',
  'running-vehicle.air_condition': 'エアコン',
  'running-vehicle.air_condition.off': 'OFF',
  'running-vehicle.air_condition.on': 'ON',
  'running-vehicle.air_condition.auto': 'AUTO',
  'running-vehicle.running_status': '走行可否',
  'running-vehicle.ahead_distance': '車間調整距離',
  'running-vehicle.lateral_position': '走行横位置',
  'running-vehicle.manual_break': 'マニュアルブレーキ',
  'running-vehicle.running_position': '走行位置',
  'running-vehicle.lap_time': 'ラップ',
  'running-vehicle.running_route': '経路情報',
  'running-vehicle.communication': '通信状況',
  'running-vehicle.power_consumption': '電力',
  'running-vehicle.electricity_cost': '電費',
  'running-vehicle.emergency_door': '非常ドア',
  'running-vehicle.steering_angle': '操舵角',
  'running-vehicle.floor': '階層',
  'running-vehicle.floor.storey': '階',
  'running-vehicle.floor.basement': '地下',
  'running-vehicle.gps_sanity': 'GPS健全度',
  'running-vehicle.data_link': 'データリンク',
  'running-vehicle.propo_mode': 'プロポモード',
  'running-vehicle.propo_communication': 'プロポ通信',
  'running-vehicle.angular_velocity': '角速度',
  'running-vehicle.roll': 'roll',
  'running-vehicle.pitch': 'pitch',
  'running-vehicle.heading': 'heading',
  'running-vehicle.flight_status.manual': '手動（タブレットモード以外）',
  'running-vehicle.flight_status.single_waypoint': 'シングルウェイポイント飛行',
  'running-vehicle.flight_status.planned': '計画飛行',
  'running-vehicle.flight_status.go_home': 'ゴーホーム',
  'running-vehicle.flight_status.emergency_landing_move': '緊急着陸地移動',
  'running-vehicle.flight_status.emergency_landing': '緊急着陸',
  'running-vehicle.flight_status.pause': '一時停止',
  'running-vehicle.flight_status.emergency_mode': '非常時モード',
  'running-vehicle.flight_status.emergency_go_home': '緊急ゴーホーム',
  'running-vehicle.flight_status.speed_command': '速度指令',

  'running-vehicle.alert': 'アラート',
  'running-vehicle.vehicle_info': '車両詳細',
  'running-vehicle.vehicle_info.auto': '自動',
  'running-vehicle.vehicle_info.manual': '手動',
  'running-vehicle.vehicle_info.battery_charge': '充電中',
  'running-vehicle.vehicle_info.communication': '通信',
  'running-vehicle.vehicle_info.control': '自動制御',
  'running-vehicle.vehicle_info.obstacle_sensor': '障害物検知',
  'running-vehicle.vehicle_info.position_sensor': '自己位置推定',
  'running-vehicle.vehicle_info.vehicle': '車両状態',
  'running-vehicle.vehicle_info.motor': 'エンジン/モーター',
  'running-vehicle.vehicle_info.engine': 'パワートレイン',
  'running-vehicle.vehicle_info.brake': 'ブレーキ',
  'running-vehicle.vehicle_info.energy': 'エネルギー',
  'running-vehicle.vehicle_info.front_door': '前方ドア',
  'running-vehicle.vehicle_info.back_door': '後方ドア',
  'running-vehicle.operation_info': '運行情報',
  'running-vehicle.vehicle_info.auto_driving': '自動走行',
  'running-vehicle.send_notification': '車内表示',
  'running-vehicle.destination.tittle': '目的地',
  'running-vehicle.destination.start': '▶  発車',
  'running-vehicle.destination.select': '地図またはリストから選択',
  'running-vehicle.destination.no_support': 'この環境では即時配車の機能は使えません。',
  'running-vehicle.vehicle_info.battery': '電池',
  'running-vehicle.vehicle_info.higher': '高度',
  'running-vehicle.vehicle_info.speed': '速度',
  'running-vehicle.address.not.get.from.vehicle':
    '車両の現在位置が取得できないため、住所情報が取得できません。',
  'running-vehicle.address.get.failed': '読み込みに失敗しました。再取得してください。',
  'running-vehicle.address.not.exist': '住所情報が存在しない位置です。',
  'running-vehicle.address.button': '住所取得',
  'running-vehicle.text_status.normal': '【正常】〇',
  'running-vehicle.text_status.abnormal': '【異常】×',
  'running-vehicle.text_status.emergency_stop_button': '非常停止ボタン',
  'running-vehicle.text_status.push': '【押下】',
  'running-vehicle.text_status.driving_assistance_device_status': '運転補助機器',
  'running-vehicle.text_status.vehicle_status': '車両状態',

  /* リマインダーモーダルについて */
  'reminder.modal.title': '通知アラート',
  'reminder.modal.message': '期限切れのファイルがあります。ご確認ください。',
  'reminder.modal.vehicle': '車両',
  'reminder.modal.office': '営業所',
  'reminder.modal.employee': '社員',
  'reminder.modal.check_message': '本日はこのメッセージを表示しない',

  /* Dispactherについて */
  'about.title_dispatcher': 'Dispatcherについて',
  'about.product_name': '商品名',
  'about.version': 'バージョン',
  'about.auto_update': '自動更新',
  'about.auto_update.value': '有効',
  'about.product_detail': '商品詳細',
  'about.product_detail.value_dispatcher': 'Dispatcher Webサイト',
  'about.developer': '開発元',
  'about.developer.value': 'BOLDLY',
  'about.developer_detail': '開発元詳細',
  'about.developer_detail.value': 'BOLDLY Webサイト',
  'about.contact': 'ご意見・ご要望',
  'about.contact.value': 'BOLDLY 連絡先',

  /* アカウントについて */
  'account.title_dispatcher':
    'Dispatcherを便利にご利用いただけるよう、個人アカウントを管理できます。',
  'account.text_1.welcome': 'ようこそ',
  'account.text_2.welcome': 'さん',
  'account.title.other': 'その他情報',
  'account.name.placeholder': '半角英数字、ｱﾝﾀﾞｰﾊﾞｰ、ﾄﾞｯﾄ、8〜16文字',
  'account.name.placeholder_2': '半角英数字、ｱﾝﾀﾞｰﾊﾞｰ、ﾄﾞｯﾄ、8〜16文字で入力してください',
  'account.name.no-match':
    'このユーザー名は使用できません。\n半角英数字、　　　ｱﾝﾀﾞｰﾊﾞｰ、ﾄﾞｯﾄを含む8〜16文字以内にしてください。',
  'account.name.message.max_length_username': 'ユーザー名は16文字以内で入力してください',
  'account.message.is_hankaku_employee_id': 'ユーザー名は半角英数字のみ入力できます',
  'account.name.invalid-character': 'ユーザー名に使用できない文字が含まれています',
  'account.name.invalid-length': 'ユーザー名は8〜16文字で入力してください',

  /* infraナビゲーションについて */
  'infra.alert': 'インフラアラート',
  'infra.infra_tab': '一覧',
  'infra.infla_alert': 'アラート情報',
  'infra.infla_alert.option_text': 'インフラ復旧後に対応完了できます',
  'infra.infla_alert.error_text': '対応の登録に失敗しました',
  'infra.infla_alert.alert_corresponded': '対応完了',
  'infra.infla_info': 'インフラ情報',
  'infra.infla_info.close': '閉じる',
  'infra.infla_alert.text': 'が発生しました。下記ステップで対応を行なってください',
  'infra.infla_alert.alert': 'アラート',

  /* エラー画面 */
  'error-boundary.title': '予期しない問題が発生いたしました',
  'error-boundary.desc': 'ページを再読み込みしてください。',
  'error-boundary.reload': '再読み込み',
  'error-boundary.link.desc1': '現象が回復しない場合は',
  'error-boundary.link.desc2': 'お問い合わせ',
  'error-boundary.link.desc3': 'ください。',

  /* 共通単語 */
  'common.title': '運行管理システム - {0}',
  'common.vehicle_name': '車両名',
  [LANGUAGE_DRIVER_KEY]: '運転士',
  [LANGUAGE_OPERATOR_KEY]: 'オペレーター',
  'common.delay_time': '遅延時間',
  'common.on_time': '定刻',
  'common.passenger_number': '乗客数',
  'common.is_next_stop': '次停車',
  'common.has_wheelchair': '車椅子客',
  'common.speed': '速度',
  'common.unknow_speed': '速度不明',
  'common.rpm': '回転数',
  'common.occupancy': '乗車率',
  'common.temperature': '室温',
  'common.fuel': '燃料',
  'common.tachograph': 'タコ',
  'common.passenger': '乗客',
  'common.fare': '運賃',
  'common.battery': '充電',
  'common.tire': 'タイヤ',
  'common.capacity': '定員',
  'common.door': 'ドア',
  'common.radiator': '冷却水温',
  'common.equipment': '車両機器',

  'common.basiclist.items': '_START_～_END_件目 / _TOTAL_件',
  'common.basiclist.items-empty': '0～0件目 / 0件',
  'common.basiclist.previous': '前のページ',
  'common.basiclist.next': '次のページ',
  'common.basiclist.no_data': 'データがありません',
  'common.placeholder.search': 'キーワード検索',

  'common.loading': '読み込み中',
  'common.available': '可能',
  'common.unavailable': '不可',
  'common.normal': '通常',
  'common.normal2': '正常',
  'common.fault': '異常',
  'common.unknown': '不明',
  'common.unacquired': '未取得',
  'common.acquired': '取得',
  'common.unset': '未設定',
  'common.not.recorded': '未登録',

  'common.there': 'あり',
  'common.none': 'なし',

  'common.open': '開',
  'common.close': '閉',

  'common.format.ymd': 'YYYY年MM月DD日 (ddd)',
  'common.format.yyyymd': 'YYYY年M月D日',
  'common.format.yyyymmddhhmmss': 'YYYY年M月D日(ddd) HH:mm:ss',
  'common.format.mmddhhmmss': 'M月D日(ddd) HH:mm:ss',
  'common.format.mmddddd': 'M月D日(ddd)',
  'common.format.datepicker.ym': 'yyyy年MM月',
  'common.format.ym': 'YYYY年MM月',
  'common.sun': '日',
  'common.mon': '月',
  'common.tue': '火',
  'common.wed': '水',
  'common.thu': '木',
  'common.fri': '金',
  'common.sat': '土',

  'common.time': '時刻',
  'common.yen': '円',
  'common.days': '日',
  'common.days.omit': '日',
  'common.hours': '時間',
  'common.hours.omit': '時',
  'common.minutes': '分',
  'common.minutes.omit': '分',
  'common.seconds': '秒',
  'common.seconds.omit': '秒',
  'common.ago': '前',
  'common.people': '人',
  'common.level': 'レベル',
  'common.byte_per_minute': 'バイト/分',
  'common.km': 'km',
  'common.km_per_hour': 'km/h',
  'common.m_per_sec2': 'm/s\u00b2',
  'common.degree': '℃',
  'common.unit.rpm': 'rpm',
  'common.unit.none': 'none',
  'common.colon': '：',
  'common.middledot': '・',
  'common.slash': '／',
  'common.comma': '、',

  'common.save': '登録',
  'common.yes': 'はい',
  'common.cancel': 'キャンセル',
  'common.add': '新規登録',
  'common.create': '新規作成',
  'common.edit': '編集',
  'common.update': '更新',
  'common.register': '登録',
  'common.storage': '保存',
  'common.delete': '削除',
  'common.search': '検索',
  'common.attach': '適用',
  'common.all': 'すべて',
  'common.clear': '選択解除',
  'common.button.close': '閉じる',
  'common.new': '新規',
  'common.plus': '+',
  'common.detail': '詳細',
  'common.temporarily-save': '一時保存',
  'common.not-implemented': '未実施',

  'common.selected': '選択中',

  'common.weekday': '平日',
  'common.holiday': '休祝日',

  'common.login': 'ログイン',
  'common.logout': 'ログアウト',
  'common.account': 'アカウント管理',

  'common.patrol': '循環',
  'common.route': '路線',
  'common.course': '行路',

  'common.required': '必須',
  'commmn.businessoperation': '営業運転',
  'commmn.fowarding': '回送',

  'common.place': '場所',
  'common.month': '月',
  'common.year': '年',

  'common.use-type.scheduled': '路線運行',
  'common.use-type.demand': '予約運行',
  'common.use-type': '用途',

  /* 共通メッセージ */
  'common.message.network_error': '通信エラー: ネットワークが正常かご確認下さい',
  'common.message.delete_confirm': '削除しますが、よろしいですか？',

  /* 合成音声 */
  'speech.voice_charactor': 'Google 日本語',
  'speech.running_vehicle.change_course': '行路が変更されました',
  'speech.running_vehicle.vehicle_start1': 'まもなく、',
  'speech.running_vehicle.vehicle_start2': '、が発車いたします',
};
