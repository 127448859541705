// View Actions
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const CLEAR_ERROR_MESSAGES = 'CLEAR_ERROR_MESSAGES';

// Init data Actions
export const INIT_DATA_COURSE = 'INIT_DATA_COURSE';
export const INIT_DATA_EMPLOYEE = 'INIT_DATA_EMPLOYEE';
export const INIT_DATA_RUNNING_DATA = 'INIT_DATA_RUNNING_DATA';
export const INIT_DATA_STATISTICS = 'INIT_DATA_STATISTICS';

export const CONTROL_TRANSITION_BUTTON_DISABLED = 'CONTROL_TRANSITION_BUTTON_DISABLED';

export const GET_IN_CHARGE_ALERT_COUNT = 'GET_IN_CHARGE_ALERT_COUNT';
export const SET_NOT_DONE_ALERT_COUNT = 'SET_NOT_DONE_ALERT_COUNT';
