import { AKA, AO, KIIRO, KURO, MIDORI } from './primitiveColor';

/**
 * @summary Dispatcherで使用するテーマカラー
 * セマンティックベース
 *
 * @description
 * 例：名前...対象
 * Text...文字
 * Button...長方形
 * Icon...イラスト(SVG)
 * Background...背景
 * Border...線
 * Chart...分析グラフ
 * Accessory...付随UIパーツ（Batchなど）
 * Status...自動運転に係る走行状態
 */
export default {
  text: {
    light: {
      default: KURO[800],
      secondary: KURO[100],
      link: AO[600],
      linkHover: AO[800],
      linkActive: AO[500],
      alert: AKA[600],
      alertHover: AKA[700],
      alertActive: AKA[500],
      warning: KIIRO[600],
      warningHover: KIIRO[800],
      warningActive: KIIRO[500],
      success: MIDORI[600],
      successHover: MIDORI[800],
      successActive: MIDORI[500],
      hover: KIIRO[100],
      label: KURO[600],
      border: KURO[500],
      disabled: KURO[700],
      primary: KURO[300],
      tertiary: KURO[400],
      divider: KURO[200],
    },
    dark: {
      // Empty
    },
  },
  icon: {
    light: {
      default: KURO[800],
      secondary: KURO[100],
      link: AO[600],
      linkHover: AO[800],
      linkActive: AO[500],
      alert: AKA[600],
      alertHover: AKA[700],
      alertActive: AKA[500],
      warning: KIIRO[600],
      warningHover: KIIRO[800],
      warningActive: KIIRO[500],
      success: MIDORI[600],
      successHover: MIDORI[800],
      successActive: MIDORI[500],
      hover: KIIRO[100],
      label: KURO[600],
      border: KURO[500],
      disabled: KURO[700],
      primary: KURO[300],
      tertiary: KURO[400],
      divider: KURO[200],
    },
    dark: {
      // Empty
    },
  },
  button: {
    light: {
      default: KURO[800],
      secondary: KURO[100],
      link: AO[600],
      linkHover: AO[800],
      linkActive: AO[500],
      alert: AKA[600],
      alertHover: AKA[700],
      alertActive: AKA[500],
      warning: KIIRO[600],
      warningHover: KIIRO[800],
      warningActive: KIIRO[500],
      success: MIDORI[600],
      successHover: MIDORI[800],
      successActive: MIDORI[500],
      hover: KIIRO[100],
      label: KURO[600],
      border: KURO[500],
      disabled: KURO[700],
      primary: KURO[300],
      tertiary: KURO[400],
      divider: KURO[200],
    },
    dark: {
      // Empty
    },
  },
  background: {
    light: {
      default: KURO[800],
      secondary: KURO[100],
      link: AO[600],
      linkSub: AO[300],
      linkHover: AO[800],
      linkActive: AO[500],
      alert: AKA[600],
      alertSub: AKA[300],
      alertHover: AKA[700],
      alertActive: AKA[500],
      warning: KIIRO[600],
      warningSub: KIIRO[300],
      warningHover: KIIRO[800],
      warningActive: KIIRO[500],
      success: MIDORI[600],
      successHover: MIDORI[800],
      successActive: MIDORI[500],
      hover: KIIRO[100],
      label: KURO[600],
      border: KURO[500],
      disabled: KURO[700],
      primary: KURO[300],
      tertiary: KURO[400],
      divider: KURO[200],
    },
    dark: {
      // Empty
    },
  },
  border: {
    light: {
      default: KURO[800],
      secondary: KURO[100],
      link: AO[600],
      linkHover: AO[800],
      linkActive: AO[500],
      alert: AKA[600],
      alertHover: AKA[700],
      alertActive: AKA[500],
      warning: KIIRO[600],
      warningHover: KIIRO[800],
      warningActive: KIIRO[500],
      success: MIDORI[600],
      successHover: MIDORI[800],
      successActive: MIDORI[500],
      hover: KIIRO[100],
      label: KURO[600],
      border: KURO[500],
      disabled: KURO[700],
      primary: KURO[300],
      tertiary: KURO[400],
      divider: KURO[200],
    },
    dark: {
      // Empty
    },
  },
  status: {
    light: {
      success: MIDORI[600],
      alert: AKA[600],
      warning: KIIRO[600],
    },
    dark: {
      // Empty
    },
  },
  chart: {
    light: {
      primary: '#d96d8d',
      secondary: '#d9b76d',
      tertiary: '#79b43f',
    },
    dark: {
      // Empty
    },
  },
  accessory: {
    light: {
      success: MIDORI[600],
      alert: AKA[600],
      warning: KIIRO[600],
    },
    dark: {
      // Empty
    },
  },
};
