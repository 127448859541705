import React from 'react';
import styled, { css } from 'styled-components';

import styleConstants from '../../contents/css/constants/styleConstants';
import CustomMedia from '../running-vehicles/CustomMedia';

interface Props {
  children: string;
  isFcBus: boolean;
  show?: boolean;
  useTopPage?: boolean;
}

const ErrorCamera: React.FC<Props> = ({ children, show = false, useTopPage, isFcBus }: Props) => {
  if (isFcBus) {
    return (
      <Div_ErrorCameraContainer $useTopPage={useTopPage} className="error-camera" $show={show}>
        {' '}
      </Div_ErrorCameraContainer>
    );
  }
  return (
    <Div_ErrorCameraContainer $useTopPage={useTopPage} className="error-camera" $show={show}>
      <P_ErrorDisplay>
        <I_ErrorIcon className="material-icons">videocam_off</I_ErrorIcon> {children}
      </P_ErrorDisplay>
    </Div_ErrorCameraContainer>
  );
};
export default ErrorCamera;

interface ErrorCameraContainer {
  $show: boolean;
  $useTopPage?: boolean;
}
const Div_ErrorCameraContainer = styled.div<ErrorCameraContainer>`
  ${({ $useTopPage }: ErrorCameraContainer) =>
    $useTopPage
      ? `
      width: 255px; //一覧画面
    `
      : `
      width: 100%; //詳細画面
    `}
  background-color: ${styleConstants.background.light.tertiary};
  position: relative;
  height: 100%;

  ${({ $show }) =>
    !$show &&
    css`
      display: none;
    `}
`;
const P_ErrorDisplay = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  width: 100%;
  margin-bottom: 0;
  position: absolute;
  top: calc(50% - 37px);
  left: 0;
  color: ${styleConstants.text.light.disabled} ${CustomMedia.lessThan('iPad')`
    font-size: 12px;
  `};
`;
const I_ErrorIcon = styled.i`
  font-size: 60px;
  color: ${styleConstants.text.light.disabled};
  display: block;
`;
