import { generateMedia } from 'styled-media-query';

/**
 * @description PCSPのブレイクポイント
 */
const CustomMedia = generateMedia({
  sm: '480px',
  md: '768px',
  iPad: '1024px',
  iPadPro: '1367px',
  pcSmall: '1440px',
  pcSmallPlus: '1679px',
  pcMid: '1730px',
});
export default CustomMedia;
