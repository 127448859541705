import { getAPIClientAsync } from '../../common/aws';
import Res from './apiPostResponse';

/**
 * @description アラートPUSHの配信を停止する
 * @param {String} token PUSHトークン
 */
export default async function unSubscribeAlertPush(): Promise<Res> {
  const apigClient = await getAPIClientAsync();

  const params = {};
  const body = {};
  const additionalParams = {
    headers: {},
    queryParams: {},
  };

  return apigClient.unsubscribeAlertPushGet(params, body, additionalParams);
}
