import { HTTP_OK } from '../../common/constants';

const okStatusList = [
  HTTP_OK,
  'SUCCESS',
  'RUNNING',
  'START',
  'PARSE',
  'VALIDATE',
  'MAPPING',
  'UPDATE',
];

/**
 * @description APIの実行が成功したか判定する
 * @param {Object} result APIのレスポンス
 * @returns true=成功,false=失敗
 */
export default function isOK(result: any) {
  if (!result || !result.data || result.data.errorMessage) {
    return false;
  }
  return !result.data.status || okStatusList.includes(result.data.status);
}
