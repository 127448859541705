import { MediaClient } from '@tier4/webauto-media-client';
import moment from 'moment';
import { TIER4, USE_TYPES } from '../../common/constants';
import ParseTime from '../../common/datetimeutil';
import DemandGetRunningDataDetail, {
  GetDemandRunningDataDetailRes,
} from '../apicall/demand/getRunningDataDetail';
import getAgencyDefinition from '../apicall/getAgencyDefinition';
import getDeviceAccessInfo from '../apicall/getDeviceAccessInfo';
import getRunningDataDetail from '../apicall/getRunningDataDetail';
import getRunningDataList, {
  GetRunningDataListData,
  GetRunningDataListOperation,
} from '../apicall/getRunningDataList';
import getVehicleList from '../apicall/getVehicleList';
import isOK from '../apicall/isOK';
import * as commonActions from '../common/actionTypes';
import * as actions from './actionTypes';

// アラート情報の形式テンプレート
const ALERT_TEMPLATE = {
  description: {
    jp: '',
    en: '',
  },
  target: {
    jp: '',
    en: '',
  },
  todo: {
    jp: '',
    en: '',
  },
};

// getDeviceAccessInfoを失敗した時の際リトライの時間[s]
const RETRY_INTERVAL_SEC = 30;

let demandTimerList: Array<{
  timer: NodeJS.Timer;
  vehicleID: number;
}> = [];

/**
 * @description api実行失敗時処理
 * @param {Object} error エラー内容
 * @param {Object} dispatch Redux Action
 */
function onFailure(error: any, dispatch: any) {
  console.info('fetchRunningDataDetails error', JSON.stringify(error));

  dispatch({
    payload: {},
    type: commonActions.STOP_LOADING,
  });
}

/**
 * @description APIから渡されたアラートメッセージ情報を使いやすい様に加工する
 * @param {Object} alertDefinition アラートメッセージ一覧
 * @returns アラートメッセージ一覧
 */
export function convertAlertKeyCodes(alertDefinition: any) {
  const keys = {
    ...alertDefinition,
  };
  const codes: any = {};
  Object.keys(alertDefinition).forEach((key) => {
    const alert = alertDefinition[key];
    if (alert && Array.isArray(alert.values)) {
      alert.values.forEach((item: any) => {
        codes[`${key}_${item.value}`] = Object.assign({}, alert);
        if (item.display) {
          codes[`${key}_${item.value}`] = Object.assign(
            {},
            codes[`${key}_${item.value}`],
            item.display,
          );
        }
        // description,target,todoの何れかのKEYがない場合付与する
        codes[`${key}_${item.value}`] = Object.assign(
          {},
          ALERT_TEMPLATE,
          codes[`${key}_${item.value}`],
        );
      });
    } else {
      codes[key] = alert;
      // description,target,todoの何れかのKEYがない場合付与する
      codes[key] = Object.assign(ALERT_TEMPLATE, codes[key]);
    }
  });

  return {
    codes,
    keys,
  };
}

/**
 * @description getAgencyDefinitionを実行する
 * @param {*} dispatch Redux Action
 * @returns getAgencyDefinitionのレスポンス
 */
async function callAgencyDefinition(dispatch: any) {
  try {
    const result = await getAgencyDefinition();

    if (!isOK(result)) {
      onFailure(result, dispatch);
      return null;
    }

    const { data } = result;
    data!.alert = convertAlertKeyCodes(data!.alert);
    // アラートエラ〜メッセージをグローバルに保存
    (window as any).loginUser.alertCodeMessages = data?.message || {};
    return data;
  } catch (error) {
    onFailure(error, dispatch);
  }
}

/**
 * @description getAgencyDefinitionを実行する
 * @returns getAgencyDefinitionのレスポンス
 */
export function fetchAgencyDefinition() {
  return async (dispatch: any) => {
    dispatch({
      payload: {},
      type: commonActions.START_LOADING,
    });

    const data = await callAgencyDefinition(dispatch);

    dispatch({
      payload: {
        data,
      },
      type: actions.FETCH_AGENCY_DEFINITION,
    });
  };
}

function dispatchRunningDataDetail(
  reservationResult: GetDemandRunningDataDetailRes,
  reservationKey: string,
  dispatch: any,
) {
  if (!isOK(reservationResult)) {
    return;
  }

  const updateReservationKey = reservationKey || `${reservationResult.data?.vehicle.vehicle_id}`;
  dispatch({
    payload: {
      updateReservationKey,
      updateRunningDataDetail: {
        ...reservationResult.data,
        use_type: USE_TYPES.demand,
        reservation_key: updateReservationKey,
      },
    },
    type: actions.FETCH_UPDATE_RUNNING_DATA,
  });
}

/**
 * @description 路線用の運行情報詳細を取得する
 * @param {Object} runningData 運行情報一覧
 * @param {Object} dispatch Redux Action
 */
async function fetchRunningDataDetails(runningData: GetRunningDataListData, dispatch: any) {
  // 車両がアサインされている行路だけにする。
  const operationOriginal = runningData.operation.filter((item) => item.vehicle_id);
  const { map, websocket_url: websocketUrl } = runningData;

  let addTier4RunningDataDetail: Array<any> = [];
  const promises: any = [];

  // TODO: バックエンドがアクティブミッションに対応するまでuse_typeにscheduledを入れておく。
  // 削除しないでもアクティブミッション対応バージョンと正しく接続できるが、不要な処理なので削除する予定。
  const operation = operationOriginal.map((item) => {
    if (item.use_type === undefined) {
      return { ...item, use_type: USE_TYPES.scheduled };
    }
    return item;
  });

  const demandList: {
    [key: number]: Array<{
      reservationKey?: string;
      vehicleID: number;
      areaID: string;
      departureTime: moment.Moment;
    }>;
  } = {};
  operation.forEach((item) => {
    if (item.use_type === USE_TYPES.scheduled) {
      promises.push(
        getRunningDataDetail(item.course_id!).catch((error) => {
          onFailure(error, dispatch);
        }),
      );
    } else {
      if (!demandList[item.vehicle_id]) {
        demandList[item.vehicle_id] = [];
      }
      demandList[item.vehicle_id].push({
        reservationKey: item.reservation_key,
        vehicleID: item.vehicle_id,
        areaID: item.area_id!,
        departureTime: ParseTime(item.departure_time).moment,
      });
    }
  });

  Object.keys(demandList).forEach((vehicleID) => {
    const reservations = demandList[Number(vehicleID)];
    // 予約が１件だけならそれを使う
    if (reservations.length === 1) {
      promises.push(
        DemandGetRunningDataDetail({
          reservationKey: reservations[0].reservationKey,
          vehicleId: reservations[0].vehicleID,
          areaId: reservations[0].areaID,
        }).catch((error) => {
          onFailure(error, dispatch);
        }),
      );
      return;
    }
    let nearTimeReservation: {
      reservationKey?: string;
      departureTime: moment.Moment;
      vehicleID: number;
      areaID: string;
    } = {
      reservationKey: '',
      departureTime: moment(),
      vehicleID: 0,
      areaID: '',
    };
    const now = moment();
    const timerList: Array<{
      reservationKey?: string;
      vehicleID: number;
      departureTime: moment.Moment;
      areaID: string;
    }> = [];
    // 現在時刻以上で最も近い時間の予約を探す
    reservations.forEach((item) => {
      if (now.diff(item.departureTime) <= 0) {
        if (!nearTimeReservation.reservationKey) {
          nearTimeReservation = item;
        } else if (nearTimeReservation.departureTime.diff(item.departureTime) > 0) {
          nearTimeReservation = item;
        }
        timerList.push(item);
      }
    });
    // 現在時刻以降のデータがない場合
    if (!nearTimeReservation.reservationKey) {
      // 現在時刻以下で最も近い時間の予約を探す
      reservations.forEach((item) => {
        if (now.diff(item.departureTime) >= 0) {
          if (!nearTimeReservation.reservationKey) {
            nearTimeReservation = item;
          } else if (nearTimeReservation.departureTime.diff(item.departureTime) < 0) {
            nearTimeReservation = item;
          }
        }
      });
    }
    promises.push(
      DemandGetRunningDataDetail({
        reservationKey: nearTimeReservation.reservationKey,
        vehicleId: nearTimeReservation.vehicleID,
        areaId: nearTimeReservation.areaID,
      }).catch((error) => {
        onFailure(error, dispatch);
      }),
    );
    // 取得対象でなかった予約はタイマーを仕掛けてのちに取得する
    timerList
      .filter((item) => item.reservationKey !== nearTimeReservation.reservationKey)
      .forEach((item) => {
        // (出発時間-10分)-現在時間でタイマーを仕掛ける
        const timerMilliseconds = item.departureTime.add(-10, 'minutes').diff(now);
        const timerID = setTimeout(async () => {
          const demandDetailresult = await DemandGetRunningDataDetail({
            reservationKey: item.reservationKey,
            vehicleId: item.vehicleID,
            areaId: item.areaID,
          });
          dispatchRunningDataDetail(demandDetailresult, item.reservationKey || '', dispatch);
        }, timerMilliseconds);
        demandTimerList.push({
          timer: timerID,
          vehicleID: item.vehicleID,
        });
      });
  });

  // 全てのruningDataDetailを並列で取得
  const result = await Promise.all(promises);

  const runningDataDetail = result
    .filter((resultItem: any) => {
      if (resultItem.data.course) {
        return true;
      }
      return isOK(resultItem) && resultItem.data.vehicle;
    })
    .map((resultItem: any) => {
      if (resultItem.data.course) {
        if (isOK(resultItem) && resultItem.data.course) {
          // runningDetaDetailにuse_typeを付与する。
          return { ...resultItem.data, use_type: USE_TYPES.scheduled };
        }
      }
      const reservation_key =
        resultItem.data.reservation?.reservation_key || `${resultItem.data.vehicle.vehicle_id}`;
      // TODO: メンテ情報を除外する
      return {
        ...resultItem.data,
        use_type: USE_TYPES.demand,
        reservation_key: reservation_key,
      };
    });

  // vehicle_number, vehicle_nameをoperationに付与する
  const operationWithVehicleInfo = operation
    .map((item: any) => {
      const targetDetail = runningDataDetail.find(
        (detailItem) => item.vehicle_id === detailItem.vehicle.vehicle_id,
      );
      if (!targetDetail) {
        return null;
      }
      return {
        ...item,
        vehicle_name: targetDetail.vehicle.vehicle_name,
        vehicle_number: targetDetail.vehicle.vehicle_number,
      };
    })
    .filter((item: any) => item);

  const nonNullRunningDataDetail = runningDataDetail.filter((item) => item);

  addTier4RunningDataDetail = nonNullRunningDataDetail;
  // tier4フラグがあればカメラデータを取得
  const isExsistTier4Vehicle = nonNullRunningDataDetail.some(
    (item) => item.vehicle.vehicle_type_id === TIER4,
  );

  if (isExsistTier4Vehicle) {
    let TimeFunctionFrag = false;

    addTier4RunningDataDetail = await Promise.all(
      nonNullRunningDataDetail.map(async (item) => {
        if (item.vehicle.vehicle_type_id !== TIER4) return item;

        let tier4Result = await getDeviceAccessInfo({
          vehicleId: item.vehicle.vehicle_id,
        });
        if (!isOK(tier4Result)) return item;

        const refreshToken = async () => {
          tier4Result = await getDeviceAccessInfo({
            vehicleId: item.vehicle.vehicle_id,
          });
          if (!isOK(tier4Result)) {
            return moment().add(RETRY_INTERVAL_SEC, 'second').unix();
          }
          MediaClient.addAuthTokenCallback(async () => {
            return {
              token: tier4Result.data!.tier4.access_token,
              expiredAt: new Date(),
            };
          });
          return tier4Result.data!.tier4.expires_in;
        };

        const tokenTimer = (timer: number) =>
          setTimeout(async () => {
            if (location.pathname === '/pages/running-vehicles') {
              const nextRefreshTime = await refreshToken();
              const expiredTime: number = nextRefreshTime - moment().unix();
              if (expiredTime > 0) {
                tokenTimer(expiredTime);
              }
            }
          }, timer * 1000);

        const nextRefreshUTime = await refreshToken();
        if (!TimeFunctionFrag) {
          TimeFunctionFrag = true;
          const expiredTime: number = nextRefreshUTime - moment().unix();
          tokenTimer(expiredTime);
        }

        // 事前にtier4カメラを取得
        const cameraResult: any = await MediaClient.getRemoteDevices({
          projectId: tier4Result.data!.tier4.project_id,
          vehicleId: tier4Result.data!.tier4.vehicle_id,
          environmentId: tier4Result.data!.tier4.environment_id,
        }).catch((err) => {
          console.info(err);
        });
        if (cameraResult) {
          item.vehicle.tier4Camera = cameraResult.cameras;
          return item;
        }
        return item;
      }),
    );
  }

  dispatch({
    payload: {
      operation: operationWithVehicleInfo.map((resultItem) => {
        if (resultItem.use_type === USE_TYPES.scheduled) {
          return resultItem;
        }
        const reservation_key = resultItem.reservation_key || `${resultItem.vehicle_id}`;

        return {
          ...resultItem,
          reservation_key,
        };
      }),
      map,
      websocketUrl,
      runningDataDetail: addTier4RunningDataDetail,
    },
    type: actions.FETCH_RUNNING_DATA,
  });
}

/**
 * @description 指定された車両だけ行路を取り直す
 * @param {Number} courseId 行路ID
 */
function waitSync(milliseconds: number) {
  const start = new Date().getTime();
  let elapsedTime = 0;

  while (elapsedTime < milliseconds) {
    elapsedTime = new Date().getTime() - start;
  }
}
export function fetchRunningDataDetail(courseId: any) {
  return async (dispatch: any) => {
    try {
      // TODO:行路の更新はDemandの場合発生しない想定今後Demandも想定するようならUSE_TYPEも引数で渡すよう修正
      const result = await getRunningDataDetail(courseId);

      if (!isOK(result)) {
        onFailure(result, dispatch);
        return;
      }

      // runningDetaDetailにuse_typeを付与する。
      dispatch({
        payload: {
          updateCourseId: courseId,
          updateRunningDataDetail: {
            ...result.data,
            use_type: USE_TYPES.scheduled,
          },
        },
        type: actions.FETCH_UPDATE_RUNNING_DATA,
      });
    } catch (error) {
      onFailure(error, dispatch);
    }
  };
}

/**
 * @description 予約用の運行情報詳細を取得する
 * @param {*} reservationKey 予約ID
 */
export function fetchDemandRunningDataDetail(reservationKey: any) {
  return async (dispatch: any) => {
    try {
      // TODO:行路の更新はDemandの場合発生しない想定今後Demandも想定するようならUSE_TYPEも引数で渡すよう修正
      const result = await DemandGetRunningDataDetail({
        reservationKey,
      });

      if (!isOK(result)) {
        onFailure(result, dispatch);
        return;
      }

      // TODO: メンテ情報を除外する
      // runningDetaDetailにuse_typeを付与する。
      dispatch({
        payload: {
          updateReservationKey: reservationKey,
          updateRunningDataDetail: {
            ...result.data,
            use_type: USE_TYPES.demand,
            reservation_key: reservationKey,
          },
        },
        type: actions.FETCH_UPDATE_RUNNING_DATA,
      });
    } catch (error) {
      onFailure(error, dispatch);
    }
  };
}

/**
 * @description 当日の運行情報一式を取得する
 */
export function fetchRunningData() {
  // タイマーが残っている場合一旦全て破棄する
  demandTimerList.forEach((item) => clearTimeout(item.timer));
  demandTimerList = [];

  return async (dispatch: any) => {
    dispatch({
      payload: {},
      type: commonActions.START_LOADING,
    });

    try {
      const runningDataList = await getRunningDataList();

      const demandVehicleList = await getVehicleList({
        useType: USE_TYPES.demand,
      });

      if (isOK(runningDataList) && isOK(demandVehicleList)) {
        fetchRunningDataDetails(runningDataList.data!, dispatch);
      } else {
        onFailure(runningDataList, dispatch);
      }
    } catch (error) {
      onFailure(error, dispatch);
    }

    dispatch({
      payload: {},
      type: commonActions.STOP_LOADING,
    });
  };
}

/**
 * @description WSからdeleteが送られた際に指定した予約を削除する
 * @param reservationKey
 * @returns
 */
export function deleteDemandRunningData(reservationKey: string) {
  return {
    payload: {
      reservationKey,
    },
    type: actions.DELETE_DEMAND_RUNNING_DATA,
  };
}

/**
 * @description WSからinsertが送られた際に指定した予約を再取得する
 * @param vehicleID
 * @returns
 */
export function fetchDemandRunningData(vehicleID: number) {
  // 指定された車両IDのタイマーを解除する
  demandTimerList
    .filter((item) => item.vehicleID === vehicleID)
    .forEach((item) => clearTimeout(item.timer));
  demandTimerList = demandTimerList.filter((item) => item.vehicleID !== vehicleID);

  return async (dispatch: any) => {
    dispatch({
      payload: {},
      type: commonActions.START_LOADING,
    });

    const demandList: {
      [key: number]: Array<{
        reservationKey?: string;
        vehicleID: number;
        areaID: string;
        departureTime: moment.Moment;
      }>;
    } = {};
    let operation: GetRunningDataListOperation[] = [];
    try {
      const runningDataList = await getRunningDataList();

      if (!isOK(runningDataList)) {
        dispatch({
          payload: {},
          type: commonActions.STOP_LOADING,
        });
        return;
      }

      const runningData = runningDataList.data!;

      operation = runningData.operation.filter((item) => item.vehicle_id);

      operation.forEach((item) => {
        if (item.use_type === USE_TYPES.demand) {
          if (!demandList[item.vehicle_id]) {
            demandList[item.vehicle_id] = [];
          }
          demandList[item.vehicle_id].push({
            reservationKey: item.reservation_key,
            vehicleID: item.vehicle_id,
            areaID: item.area_id!,
            departureTime: ParseTime(item.departure_time).moment,
          });
        }
      });
    } catch (error) {
      onFailure(error, dispatch);
    }

    let promises: Promise<any>;
    if (demandList[vehicleID]) {
      const reservations = demandList[vehicleID];
      if (reservations.length === 1) {
        promises = DemandGetRunningDataDetail({
          reservationKey: reservations[0].reservationKey,
          vehicleId: reservations[0].vehicleID,
          areaId: reservations[0].areaID,
        });
      } else {
        let nearTimeReservation: {
          reservationKey?: string;
          departureTime: moment.Moment;
          vehicleID: number;
          areaID: string;
        } = {
          reservationKey: '',
          departureTime: moment(),
          vehicleID: 0,
          areaID: '',
        };
        const now = moment();
        const timerList: Array<{
          reservationKey?: string;
          vehicleID: number;
          departureTime: moment.Moment;
          areaID: string;
        }> = [];
        // 現在時刻以上で最も近い時間の予約を探す
        reservations.forEach((item) => {
          if (now.diff(item.departureTime) <= 0) {
            if (!nearTimeReservation.reservationKey) {
              nearTimeReservation = item;
            } else if (nearTimeReservation.departureTime.diff(item.departureTime) > 0) {
              nearTimeReservation = item;
            }
            timerList.push(item);
          }
        });
        // 現在時刻以降のデータがない場合
        if (!nearTimeReservation.reservationKey) {
          // 現在時刻以下で最も近い時間の予約を探す
          reservations.forEach((item) => {
            if (now.diff(item.departureTime) >= 0) {
              if (!nearTimeReservation.reservationKey) {
                nearTimeReservation = item;
              } else if (nearTimeReservation.departureTime.diff(item.departureTime) < 0) {
                nearTimeReservation = item;
              }
            }
          });
        }
        promises = DemandGetRunningDataDetail({
          reservationKey: nearTimeReservation.reservationKey,
          vehicleId: nearTimeReservation.vehicleID,
          areaId: nearTimeReservation.areaID,
        });
        // 取得対象でなかった予約はタイマーを仕掛けてのちに取得する
        timerList
          .filter((item) => item.reservationKey !== nearTimeReservation.reservationKey)
          .forEach((item) => {
            // (出発時間-10分)-現在時間でタイマーを仕掛ける
            const timerMilliseconds = item.departureTime.add(-10, 'minutes').diff(now);
            const timerID = setTimeout(async () => {
              const demandDetailresult = await DemandGetRunningDataDetail({
                reservationKey: item.reservationKey,
                vehicleId: item.vehicleID,
                areaId: item.areaID,
              });
              dispatchRunningDataDetail(demandDetailresult, item.reservationKey || '', dispatch);
            }, timerMilliseconds);
            demandTimerList.push({
              timer: timerID,
              vehicleID: item.vehicleID,
            });
          });
      }
    } else {
      dispatch({
        payload: {},
        type: commonActions.STOP_LOADING,
      });
      return;
    }

    // 全てのruningDataDetailを並列で取得
    const result = await promises!.catch((error) => onFailure(error, dispatch));

    if (!isOK(result)) {
      dispatch({
        payload: {},
        type: commonActions.STOP_LOADING,
      });
      return;
    }

    const updateReservationKey =
      result.data?.reservation?.reservation_key || `${result.data?.vehicle.vehicle_id}`;
    const runningDataDetail = {
      ...result.data,
      use_type: USE_TYPES.demand,
      reservation_key: updateReservationKey,
    };

    // tier4フラグがあればカメラデータを取得
    if (runningDataDetail.vehicle.vehicle_type_id === TIER4) {
      const tier4Result = await getDeviceAccessInfo({
        vehicleId: runningDataDetail.vehicle.vehicle_id,
      });
      if (!isOK(tier4Result)) {
        dispatch({
          payload: {},
          type: commonActions.STOP_LOADING,
        });
        return;
      }
      MediaClient.addAuthTokenCallback(async () => ({
        token: tier4Result.data!.tier4.access_token,
        expiredAt: new Date(),
      }));
      // 事前にtier4カメラを取得
      const cameraResult: any = await MediaClient.getRemoteDevices({
        projectId: tier4Result.data!.tier4.project_id,
        vehicleId: tier4Result.data!.tier4.vehicle_id,
        environmentId: tier4Result.data!.tier4.environment_id,
      }).catch((err) => {
        console.info(err);
      });
      if (cameraResult) {
        runningDataDetail.vehicle.tier4Camera = cameraResult.cameras;
      }
    }

    dispatch({
      payload: {
        operation,
        vehicleID,
        runningDataDetail,
      },
      type: actions.FETCH_DEMAND_RUNNING_DATA,
    });

    dispatch({
      payload: {},
      type: commonActions.STOP_LOADING,
    });
  };
}

/**
 * @description 画面移動時にタイマーをクリアする
 */
export function clearDemandTimer() {
  // 指定された車両IDのタイマーを解除する
  demandTimerList.forEach((item) => clearTimeout(item.timer));

  demandTimerList = [];

  return {
    payload: {},
    type: actions.CLEAR_DEMAND_TIMER,
  };
}

/**
 * @description 運行中一覧で車両選択時の処理
 * @param {*} vehicle 選択された車両
 */
export function selectVehicle(vehicle: any) {
  return {
    payload: { vehicle },
    type: actions.SELECT_VEHICLE,
  };
}

/**
 * @description Mapコンポーネント使用時、Mapboxオブジェクトを他のコンポーネントに配信する
 * @param {Object} map Mapboxの地図コンポーネント
 */
export function setLiveMap(map: any) {
  return {
    payload: { map },
    type: actions.SET_LIVE_MAP,
  };
}

/**
 * @description MapコンポーネントUnmount時、設定されたMapboxオブジェクトを破棄する
 */
export function clearLiveMap() {
  return {
    payload: { map: undefined },
    type: actions.CLEAR_LIVE_MAP,
  };
}

/**
 * @description 運行中一覧から遷移時、設定された当日の運行情報一式を破棄する
 */
export function clearRunningData() {
  return {
    payload: null,
    type: actions.CLEAR_RUNNING_DATA,
  };
}
