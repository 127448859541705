// Push通知周りを管理する。
import * as types from '../../actions/notification/actionTypes';

export interface NotificationReducerState {
  alertVehicleId: any;
}
const defaultState = {
  alertVehicleId: undefined,
};

/**
 * @description PUSH通知のイベントを管理するのReducer
 * @param {Object} [state=defaultState] reducer state
 * @param {Object} action redux actoon
 */
export default (state: NotificationReducerState = defaultState, action: any) => {
  switch (action.type) {
    case types.SERVICE_WORKER_INIT:
    case types.ALERT_DEFINITION_INIT:
    case types.NOTIFICATION_SUBSCRIBE:
      return state;
    case types.NOTIFICATION_CLICK: {
      return Object.assign({}, state, {
        alertVehicleId: action.payload.alertVehicleId,
      });
    }
    case types.RESET_NOTIFICATION: {
      return Object.assign({}, state, {
        alertVehicleId: action.payload.alertVehicleId,
      });
    }
    default:
      return state;
  }
};
