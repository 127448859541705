// 遠隔監視の基本情報を管理する。
import moment from 'moment';
import * as types from '../../actions/running/actionTypes';
import { ExtendMapboxGlMap } from '../../pages/running-vehicles/Map';

import _ from 'lodash';
import { TIER4 } from '../../common/constants';

export interface RunningReducerMapState {
  default_lat: number;
  default_lon: number;
}
export interface RunningReducerState {
  operation: any[];
  map: RunningReducerMapState;
  liveMap: ExtendMapboxGlMap | null;
  runningDataDetail: any[];
  selectedVehicle: { [key: string]: any };
  websocketUrls: any[];
  vehicleLiveDataMap: { [key: string]: any };
}
const { sessionStorage } = window;
const runningReducerMap = sessionStorage.getItem('runningReducer.map');
const defaultState: RunningReducerState = {
  operation: [],
  map: runningReducerMap ? JSON.parse(runningReducerMap) : { default_lat: 0, default_lon: 0 },
  liveMap: null,
  runningDataDetail: [],
  selectedVehicle: {},
  websocketUrls: [],
  vehicleLiveDataMap: {},
};

/**
 * @description 運行情報を管理するReducer
 * @param {Object} [state=defaultState] reducer state
 * @param {Object} action redux actoon
 */
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state: RunningReducerState = defaultState, action: any) => {
  switch (action.type) {
    case types.FETCH_RUNNING_DATA: {
      const { operation, map, websocketUrl, runningDataDetail } = action.payload;
      sessionStorage.setItem('runningReducer.map', JSON.stringify(map));

      return Object.assign({}, state, {
        operation,
        map,
        websocketUrls: websocketUrl,
        runningDataDetail,
      });
    }
    case types.FETCH_DEMAND_RUNNING_DATA: {
      const { operation, runningDataDetail: addRunningDataDetail, vehicleID } = action.payload;
      const { runningDataDetail } = state;
      // 既存のDetailから対象の車両IDの情報を一旦削除
      const newRunningDataDetail = runningDataDetail.filter(
        (item) => item.vehicle.vehicle_id !== vehicleID,
      );

      const targetPrevRunningDataDetail = runningDataDetail.find(
        (item) => item.vehicle.vehicle_id === vehicleID,
      );
      if (
        targetPrevRunningDataDetail.vehicle.vehicle_type_id === TIER4 &&
        !!targetPrevRunningDataDetail.vehicle.tier4Camera
      ) {
        addRunningDataDetail.vehicle.tier4Camera = targetPrevRunningDataDetail.vehicle.tier4Camera;
      }

      // 再度追加
      newRunningDataDetail.push(addRunningDataDetail);
      return Object.assign({}, state, {
        operation,
        runningDataDetail: newRunningDataDetail,
      });
    }
    case types.DELETE_DEMAND_RUNNING_DATA: {
      return state;
    }
    case types.FETCH_UPDATE_RUNNING_DATA: {
      // 指定された車両の行路を更新
      const { updateCourseId, updateReservationKey, updateRunningDataDetail } = action.payload;
      const { selectedVehicle, runningDataDetail, liveMap } = state;

      const newRunningDataDetail: any[] = Object.assign([], runningDataDetail);

      // NOTE: tier4カメラ情報が同車両のデータに既に存在していれば引き継ぎ
      const targetUpdateRunningDataDetail = _.cloneDeep(updateRunningDataDetail);
      const targetTier4 = newRunningDataDetail.find(
        (detail) =>
          detail.vehicle.vehicle_id === targetUpdateRunningDataDetail.vehicle.vehicle_id &&
          detail.vehicle.vehicle_type_id === TIER4 &&
          !!detail.vehicle.tier4Camera,
      );
      if (targetTier4) {
        targetUpdateRunningDataDetail.vehicle.tier4Camera = targetTier4.vehicle.tier4Camera;
      }

      if (updateCourseId) {
        state.runningDataDetail.forEach((data: any, index: any) => {
          if (_.get(data, 'course.course_id') === updateCourseId) {
            newRunningDataDetail[index] = targetUpdateRunningDataDetail;
          }
        });

        // selectedVehicleが更新対象の行路なら内容を入れ替える
        if (selectedVehicle?.runningDataDetail?.course.course_id === updateCourseId) {
          selectedVehicle.runningDataDetail = targetUpdateRunningDataDetail;
          selectedVehicle.runningDataDetail.updateTimeStamp = moment().unix();
        }
      }
      if (updateReservationKey) {
        const findItemIndex = runningDataDetail.findIndex((data) => {
          return data.reservation?.reservation_key === updateReservationKey;
        });
        if (findItemIndex >= 0) {
          newRunningDataDetail[findItemIndex] = targetUpdateRunningDataDetail;
        } else {
          newRunningDataDetail.push(targetUpdateRunningDataDetail);
        }
        state.runningDataDetail.forEach((data: any, index: any) => {
          if (data.reservation?.reservation_key === updateReservationKey) {
            newRunningDataDetail[index] = targetUpdateRunningDataDetail;
          }
        });
        // selectedVehicleが更新対象の行路なら内容を入れ替える
        if (
          selectedVehicle?.runningDataDetail?.vehicle?.vehicle_id ===
          targetUpdateRunningDataDetail.vehicle?.vehicle_id
        ) {
          selectedVehicle.runningDataDetail = targetUpdateRunningDataDetail;
          selectedVehicle.runningDataDetail.updateTimeStamp = moment().unix();
        }
      }

      // マップの線とアイコンを一度全削除する。
      if (liveMap) {
        // 線とアイコンを再度引くためタイムスタンプを更新する。
        liveMap.timestamp = moment();
      }

      return Object.assign({}, state, {
        runningDataDetail: newRunningDataDetail,
        selectedVehicle,
        liveMap,
      });
    }
    case types.SELECT_VEHICLE: {
      const { vehicle } = action.payload;
      if (vehicle && vehicle.runningDataDetail) {
        vehicle.runningDataDetail.updateTimeStamp = moment().unix();
      }
      return Object.assign({}, state, {
        selectedVehicle: vehicle,
      });
    }
    case types.SET_LIVE_MAP: {
      const { map } = action.payload;
      return Object.assign({}, state, {
        liveMap: map,
      });
    }
    case types.CLEAR_LIVE_MAP: {
      return Object.assign({}, state, {
        liveMap: undefined,
      });
    }
    case types.CLEAR_RUNNING_DATA: {
      return Object.assign({}, state, {
        operation: [],
        runningDataDetail: null,
      });
    }
    default:
      return state;
  }
};
