import { toast } from 'react-toastify';
import { ALERT_SOUND_KEY, ALERT_SOUND_MUTE_KEY, ALERT_SOUND_VOLUME } from './constants';

const lostConnectionToast = (text: string, option: {}) => {
  if (!window.loginUser?.services?.is_disconnect_camera) {
    return;
  }
  const sound = new Audio('/static/set/mp3/live/lost_connection.mp3');
  let volume = Number(window.localStorage.getItem(ALERT_SOUND_KEY) || ALERT_SOUND_VOLUME);
  if (window.localStorage.getItem(ALERT_SOUND_MUTE_KEY) === 'true') {
    volume = 0;
  }
  sound.volume = volume;
  if (sound.play() !== undefined) {
    sound.play().catch((err) => {
      console.log(err);
    });
  }
  toast.warn(text, option);
};

export default lostConnectionToast;
