import * as types from '../../actions/infraAlert/actionTypes';

export interface InfraAlertCountReducerState {
  infraAlertListCount: number;
}

const defaultState = {
  infraAlertListCount: 0,
};

/**
 * @description メニューのバッヂに表示するアラートカウントのReducer
 * @param {Object} [state=defaultState] reducer state
 * @param {Object} action redux actoon
 */
export default (state: InfraAlertCountReducerState = defaultState, action: any) => {
  switch (action.type) {
    case types.INFRA_ALERT_LIST_COUNT:
      return Object.assign({}, state, {
        ...action.payload,
      });

    default:
      return state;
  }
};
