import moment from 'moment';

/**
 * @description 稼働時間間隔(04:00～27:59)に乗っ取った日付を返す
 * @returns momentオブジェクト
 */
export default function getToday() {
  const today = moment();

  // 4時より前なら前日を返す
  if (today.hour() < 4) {
    return today.add(-1, 'd');
  }
  return today;
}
