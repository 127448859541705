import { GetAppendixListDataItem } from '../../actions/apicall/getAppendixList';
import * as types from '../../actions/appendixReminder/actionTypes';

export interface AppendixReminderReducerState {
  vehicleReminder: GetAppendixListDataItem[];
  officeReminder: GetAppendixListDataItem[];
  employeeReminder: GetAppendixListDataItem[];
}
const defaultState = {
  vehicleReminder: [],
  officeReminder: [],
  employeeReminder: [],
};

export default (state: AppendixReminderReducerState = defaultState, action: any) => {
  switch (action.type) {
    case types.GET_APPENDIX_REMINDER: {
      return Object.assign({}, state, {
        ...action.payload,
      });
    }
    default:
      return state;
  }
};
