// https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html

/**
 * @description Prommiseがreject時にhasCanceled_を返す？（よくわからない）
 * @param {Object} promise 実行するPromiseオブジェクト
 * @return {*} Promiseの結果
 */
const makeCancelable = (promise: any) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then(
        (val: any) => (hasCanceled_ ? reject(new Error('Canceled')) : resolve(val)),
        (error: any) => (hasCanceled_ ? reject(new Error('Canceled')) : reject(error)),
      )
      .catch((error: any) => (hasCanceled_ ? reject(new Error('Canceled')) : reject(error)));
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};
export default makeCancelable;
