import { getAPIClientAsync } from '../../common/aws';
import { CommonDataRes } from './apiGetResponse';
import { GetStopListDataItem } from './getStopList';
import { GetTripListDataItem } from './getTripList';

export type GetRunningDataDetailStopItem = Pick<
  GetStopListDataItem,
  'stop_id' | 'stop_name' | 'stop_lat' | 'stop_lon'
> & {
  sequence: number;
  is_skip: number;
  arrival_time: string;
  departure_time: string;
};

export type GetRunningDataDetailTripItem = Pick<GetTripListDataItem, 'trip_id' | 'trip_name'> & {
  trip_forwarding_flag: number;
  sequence: number;
  shape_id?: number;
  lines?: number[][][] | number[][];
  stops: GetRunningDataDetailStopItem[];
};

export interface GetRunningDataDetailCourse {
  course_id: number;
  course_name: string;
  course_number?: string;
  service_id: number;
  trips?: GetRunningDataDetailTripItem[];
}

export interface GetRunningDataDetailEmployee {
  employee_id: number;
  employee_number: string;
  employee_first_name: string;
  employee_last_name: string;
}

export interface VehicleCapabilitiesData {
  behavior: {
    // DBの中身がそのまま入る
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [index: string]: any;
  };
  key: string;
  priority: number;
}
export interface VehicleCapabilities {
  alert: string;
  data: VehicleCapabilitiesData[];
  function: string[];
}

export interface GetRunningDataDetailVehicle {
  vehicle_id: number;
  vehicle_name: string;
  vehicle_number: string;
  vehicle_maker: string;
  vehicle_type: number;
  vehicle_type_id?: number;
  capacity: number;
  wheelchair_accesible?: number;
  bikes_allowed?: number;
  vehicle_tel?: string;
  camera_layout?: string;
  maincamera_id?: string;
  cameras?: Array<{
    camera_id: string;
    camera_name: string;
    position: string;
    resolution: {
      width: number;
      height: number;
    };
  }>;
  api_version: string;
  capabilities?: VehicleCapabilities;
}

export interface GetRunningDataDetailCheckStatus {
  before: boolean;
  after: boolean;
}
export interface GetRunningDataDetailData extends CommonDataRes {
  course: GetRunningDataDetailCourse;
  employee: GetRunningDataDetailEmployee;
  vehicle: GetRunningDataDetailVehicle;
  check_status?: GetRunningDataDetailCheckStatus;
}
export interface GetRunningDataDetailRes {
  data?: GetRunningDataDetailData;
  status?: number;
  code?: string;
  message?: string;
}

/**
 * @description 運行中路線の詳細情報を取得する
 * @param {Number} courseID 行路ID
 */
export default async function getRunningDataDetail(
  courseID: number,
): Promise<GetRunningDataDetailRes> {
  const apigClient = await getAPIClientAsync();

  const params = {
    course_id: courseID,
  };

  const additionalParams = {
    headers: {},
    queryParams: {},
  };

  const body = {};

  return apigClient.getRunningDataDetailGet(params, body, additionalParams);
}
