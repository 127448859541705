import { getAPIClientAsync } from '../../common/aws';
import { CommonDataRes } from './apiGetResponse';

export interface GetDeviceAccessInfoReq {
  vehicleId: number;
}

export interface GetDeviceAccessInfoRes {
  data?: {
    tier4: {
      vehicle_id: string;
      project_id: string;
      environment_id: string;
      access_token: string;
      refresh_token: string;
      token_type: string;
      expires_in: number;
      scope: string;
    };
  } & CommonDataRes;
  status?: number;
  code?: string;
  message?: string;
}

/**
 * @description OEMデバイスにアクセスするための情報を取得する(Tier4車両用)
 * @param {Number} vehicleId 車両ID
 */
export default async function getDeviceAccessInfo({
  vehicleId,
}: GetDeviceAccessInfoReq): Promise<GetDeviceAccessInfoRes> {
  const apigClient = await getAPIClientAsync();
  const params = {
    vehicle_id: vehicleId || null,
  };

  const additionalParams = {
    headers: {},
    queryParams: {},
  };

  const body = {};

  return apigClient.getDeviceAccessInfoGet(params, body, additionalParams);
}
