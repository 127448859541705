import styled from 'styled-components';

export const Div_Error = styled.div`
  width: 100vw;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Div_ErrorBox = styled.div`
  width: 550px;
  height: 420px;
  background: rgba(255, 255, 255, 1);
  text-align: center;
`;

export const Div_IconRow = styled.div`
  padding-top: 60px;
`;

export const Div_HeaderRow = styled.div`
  padding-top: 14px;
`;

export const Button_Reload = styled.button`
  width: 180px;
`;

export const Div_LinkRow = styled.div`
  padding-top: 38px;
`;
