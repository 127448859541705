import * as types from '../../actions/common/actionTypes';

export interface AlertReducerState {
  inChargeAlertCount: number;
  notDoneAlertCount: number;
}

const defaultState = {
  inChargeAlertCount: 0,
  notDoneAlertCount: 0,
};

/**
 * @description メニューのバッヂに表示するアラートカウントのReducer
 * @param {Object} [state=defaultState] reducer state
 * @param {Object} action redux actoon
 */
export default (state: AlertReducerState = defaultState, action: any = '') => {
  switch (action.type) {
    case types.GET_IN_CHARGE_ALERT_COUNT:
      return Object.assign({}, state, {
        ...action.payload,
      });
    case types.SET_NOT_DONE_ALERT_COUNT:
      return Object.assign({}, state, {
        ...action.payload,
      });
    default:
      return state;
  }
};
