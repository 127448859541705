import * as types from '../../actions/reserveschedule/actionTypes';

export interface ReserveScheduleReducerState {
  areaId: string;
  vehicleId: number;
  start: string;
  end: string;
  canNotReserve: boolean;
}
const defaultState: ReserveScheduleReducerState = {
  areaId: '',
  vehicleId: 0,
  start: '',
  end: '',
  canNotReserve: false,
};
const defaultAction = {
  type: '',
};

/**
 * @description 予約登録中状態を管理するReducer
 * @param state [state=defaultState] reducer state
 * @param action redux actoon
 */
export default function (
  state: ReserveScheduleReducerState = defaultState,
  action: any = defaultAction,
) {
  switch (action.type) {
    case types.SET_RESERVE: {
      const { areaId, vehicleId, start, end } = action.payload;
      return Object.assign({}, state, {
        areaId,
        vehicleId,
        start,
        end,
      });
    }
    case types.CAN_NOT_RESERVE: {
      const { canNotReserve } = action.payload;
      return Object.assign({}, state, {
        canNotReserve,
      });
    }
    case types.CLEAR_RESERVE: {
      return Object.assign({}, defaultState);
    }
    default:
      return state;
  }
}
