import _ from 'lodash';
import * as types from '../../actions/infra/actionTypes';

export type SignalLight = 'green' | 'yellow' | 'red' | 'yflush' | 'rflush' | 'error';

export interface SignalGroup {
  signal_id: number;
  light: SignalLight;
  time?: number; // 秒 SSS.xxx 100ms単位 押しボタン式信号の場合は送信されない
}

export interface Intersection {
  infra_id: number;
  timestamp: number; // unixtime
  signal_group: SignalGroup[];
  has_alert: boolean;
}

export type IntersectionReducerState = { [key: number]: Intersection };

const defaultState = {};

/**
 * [topic]信号情報取得mqtt_over_websocket
 */
export default (state: IntersectionReducerState = defaultState, action: any = { type: '' }) => {
  switch (action.type) {
    case types.SET_INFRA:
      const updateIntersectons = _.cloneDeep(state);
      updateIntersectons[action.payload.infra_id] = action.payload;
      return updateIntersectons;
    case types.CLEAR_INFRA:
      return defaultState;
    default:
      return state;
  }
};
