// View Actions
export const INIT_TWILIO = 'INIT_TWILIO';
export const RECEIVE_INCOMING_CALL = 'RECEIVE_INCOMING_CALL';
export const CANCEL_CALL = 'CANCEL_CALL';
export const ACCEPT_CALL = 'ACCEPT_CALL';
export const MAKE_CALL_TO_VEHICLE = 'MAKE_CALL_TO_VEHICLE';
export const IGNORE_CALL = 'IGNORE_CALL';
export const HANGUP_CALL = 'HANGUP_CALL';
export const REJECT_CALL = 'REJECT_CALL';
export const DISCONNECT_CALL = 'DISCONNECT_CALL';

export const TWILIO_FETCH_VEHICLE_NAME = 'TWILIO_FETCH_VEHICLE_NAME';
