/**
 * @summary geolocationのwatchPositionリスナー
 */

import * as actions from './actionTypes';

let watchId: any = null;

/**
 * @description ブラウザの位置情報を取得する（SP用機能）
 * @returns 位置情報取得時に緯度経度を非同期で返す
 */
export function startWatch() {
  return (dispatch: any) => {
    watchId = navigator.geolocation.watchPosition(
      ({ coords }) => {
        dispatch({
          payload: {
            coords,
          },
          type: actions.GEOLOCATION_CHANGE_NOTIFICATION,
        });
      },
      (err) => console.info(err),
      {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 1000,
        // accuracy: 10,
      },
    );

    return {
      payload: {},
      type: actions.GEOLOCATION_WATCH_START,
    };
  };
}

/**
 * @description 現時点では停止する必要がないので将来のための機能
 * @returns なし
 */
export function StopWatch() {
  navigator.geolocation.clearWatch(watchId);
  return {
    payload: {},
    type: actions.GEOLOCATION_WATCH_STOP,
  };
}
