import isOK from '../actions/apicall/isOK';
import { LANGUAGE_DRIVER_KEY, LANGUAGE_OPERATOR_KEY } from '../common/constants';
import en from './en';
import jp from './jp';

/**
 * @description 言語ファイルクラス
 */
class Lang {
  _env: any;

  _errorCode: any;

  _lang: any;

  constructor() {
    this._lang = null;
    this._errorCode = null;
    this._env = '';
  }

  /**
   * @description ログイン時に設定した言語を設定する
   * @param {String} env 言語(jp,en)
   */
  setEnv(env: string) {
    switch (env.toLowerCase()) {
      case 'en': {
        this._lang = en; // 英語
        break;
      }
      case 'en-us': {
        this._lang = en; // 英語（アメリカ合衆国）
        break;
      }
      case 'jp': {
        this._lang = jp; // 日本語
        break;
      }
      default:
        this._lang = jp;
    }
    this._env = env || 'jp';
  }

  /**
   * @description 指定されたIDのメッセージを取得する
   * @param {String} type メッセージID
   * @returns メッセージ
   */
  get(type: string) {
    return this._lang[type];
  }

  /**
   * @description 指定されたIDのメッセージをフォーマットして取得する
   * @returns メッセージ
   */
  getFormat(type: string, ...keys: any[]) {
    let strings = this.get(type) || '';

    keys.forEach((key, i) => {
      strings = strings.replace(new RegExp(`\\{${i}\\}`, 'gm'), key);
    });

    return strings;
  }

  /**
   * @description メッセージの中に「運転手」が含まれる場合、環境毎にあった文言に変換して取得する
   * @param type メッセージID
   * @returns メッセージ
   */
  getDriverOperatorText(type: string) {
    const text = this._lang[LANGUAGE_OPERATOR_KEY];
    return this.getFormat(type, text);
  }

  /**
   * @description 現在の言語を取得する
   * @returns 言語(jp,en)
   */
  getLanguage(): string {
    return this._env;
  }

  /**
   * @description APIがエラーメッセージを返した際にエラーコードをメッセージに変換する
   * （getAlertDefinitonから取得する方法に変更したため非推奨）
   * @param {Object} result APIのレスポンス
   * @returns メッセージ
   */
  getAPIErrorMessage(result: any) {
    if (isOK(result)) {
      return '';
    }

    const messages = (window as any).loginUser.alertCodeMessages || {};
    const error = messages.error || {};

    const code = result?.data?.code || '';

    const message = error[code];

    if (!message) {
      return this._lang['common.message.network_error'];
    }

    return message[this._env];
  }
}

let lang = 'jp';

// セッションパラメータから判断
if ((window as any).loginUser && 'language' in (window as any).loginUser) {
  lang = (window as any).loginUser.language;
}

const l = new Lang();
l.setEnv(lang);

export default l;
